import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiRadioGroup,
  EuiRadioGroupOption,
  EuiSpacer,
  EuiSwitch,
  EuiText,
  EuiTitle
} from '@elastic/eui'
import {
  BookingWindow,
  DefaultBookingWindow,
  bookingWindowOptions,
  filterBookingWindowOptions,
  getTimeWindow
} from '@fallonsolutions/appointment'
import { dateConfig, dateUtils } from '@fallonsolutions/date'
import { DefaultTimeAllowed, JobAssignment, TimeAllowed, TimeAllowedOptions, getTimeAllowedHours } from '@fallonsolutions/job'
import { PriorityTypeKey } from '@fallonsolutions/priority'
import { find, includes } from 'lodash-es'
import { DateTime } from 'luxon'
import { useEffect, useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import {
  CustomerType,
  Flexibility,
  JobCategoryId,
  JobStatusType,
  JobTopic,
  JobType,
  LegacyBookingWindow,
  PriorityType,
  ScheduleEventFragment,
  ScheduleFlexibility,
  TimeWindow,
  TradeType
} from '../../../api/generated-types'
import { useNotification } from '../../../app/notification-provider'
import { useAuthenticated } from '../../../auth/authenticated-context'
import { Callout } from '../../../common/callout'
import { decamelise } from '../../../common/utils'
import { ReservedScheduleEvents } from '../../../jobs/reserved-schedule-events'
import { ServiceAreaServiceCheck } from '../../../service-area/service-area-service-check'
import { JobRequirementsPreview } from '../../helpers/job-requirements-preview'
import { GetServiceArea } from '../../helpers/service-area'
import { WorkflowAction } from '../../workflow-action'
import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../../workflow-model'
import { CustomerQualificationActionResult } from '../action-customer-qualification'
import { JobClassificationActionResult } from '../action-job-classification-types'
import { ScheduleReservationDisclaimers } from './schedule-reservation-disclaimers'

const jobStatusOptions = [
  {
    id: JobStatusType.OnHold,
    label: 'On Hold'
  },
  {
    id: JobStatusType.WaitingForParts,
    label: 'Waiting for parts'
  },
  {
    id: JobStatusType.Complete,
    label: 'Completed'
  }
]

const defaultDelayedJobStatus = JobStatusType.OnHold

export interface ReservationDetail {
  timeWindow?: TimeWindow
  reserveNow: boolean
  delayedJobStatus?: JobStatusType
  reservedScheduleEvent?: ScheduleEventFragment
  reservedScheduleEventId?: string
  technicianName?: string
  startDate: string
  bookingWindow: BookingWindow
  timeAllowed: TimeAllowed
  flexibility: Flexibility
  membershipJob?: JobCategoryId
  vaccinatedTech: boolean
}

export interface ScheduleReservationActionInput extends BaseActionInput {
  topic: JobTopic
  jobClassification: JobClassificationActionResult
  companyId: string
  suburbId?: string
  jobCreated: boolean
  customerContact?: CustomerQualificationActionResult
  assignment?: JobAssignment
}

export interface ScheduleReservationActionResult extends BaseActionResult {
  reserveNow?: boolean
  delayedJobStatus?: JobStatusType
  reservationDetail?: ReservationDetail
  startDate?: string
  bookingWindow?: BookingWindow
  timeAllowed?: TimeAllowed
  vaccinatedTech?: boolean
  scheduleFlexibility?: ScheduleFlexibility
  priority?: PriorityTypeKey
}

// eslint-disable-next-line complexity,max-lines-per-function
export const ScheduleReservationAction = (
  props: WorkflowActionProps<ScheduleReservationActionInput, ScheduleReservationActionResult>
) => {
  const notificationContext = useNotification()

  const reservationDetail = props.result?.reservationDetail

  const { onUpdate, input } = props

  const { jobClassification, jobCreated, suburbId, customerContact, companyId, topic: jobTopic } = input

  const customerType = jobClassification.topic?.customerType ?? jobClassification.customerType ?? CustomerType.Domestic
  const trade = jobClassification.topic?.trade ?? jobClassification.trade ?? TradeType.None
  const { topic } = jobClassification
  const { requirements, assignment } = topic ?? {}

  const defaultTimeAllowedForJob = getTimeAllowedHours({
    customerType,
    tradeType: trade,
    topicType: input.topic.reference,
    jobType: jobClassification?.type ?? JobType.Service,
    afterHours: false
  })

  const now = dateUtils.now()
  const result = {
    reserveNow: true,
    delayedJobStatus: reservationDetail?.delayedJobStatus,
    startDate: reservationDetail?.startDate ?? now.toISO(),
    bookingWindow: reservationDetail?.bookingWindow ?? DefaultBookingWindow,
    timeAllowed: reservationDetail?.timeAllowed ?? defaultTimeAllowedForJob ?? DefaultTimeAllowed,
    vaccinatedTech: reservationDetail?.vaccinatedTech ?? false,
    scheduleFlexibility: reservationDetail?.flexibility?.schedule ?? ScheduleFlexibility.Inflexible,
    ...props.result
  }
  const {
    reserveNow,
    delayedJobStatus,
    startDate,
    bookingWindow,
    timeAllowed,
    vaccinatedTech,
    // priority,
    scheduleFlexibility,
    actionCompleted
  } = result

  const { serviceArea } = GetServiceArea({ companyId, suburbId })
  const [reservedScheduleEvent, setReservedScheduleEvent] = useState<ScheduleEventFragment | undefined>(
    reservationDetail?.reservedScheduleEvent
  )

  const setReserveNow = (reserveNow: boolean) => {
    if (!reserveNow && !delayedJobStatus) {
      onUpdate({ ...result, reserveNow, delayedJobStatus: JobStatusType.OnHold })
    } else {
      onUpdate({ ...result, reserveNow })
    }
  }
  const setDelayedJobStatus = (delayedJobStatus: JobStatusType | undefined) => onUpdate({ ...result, delayedJobStatus })
  const onSetReservedScheduleEvent = (newEvent: ScheduleEventFragment | undefined) => {
    console.log('onSetReservedScheduleEvent', { jobCreated, actionCompleted, newEvent })
    setReservedScheduleEvent(newEvent)
    const startDate = newEvent ? newEvent.scheduled.from : now.toISO()

    const reservationDetail = createReservationDetail({
      reservedScheduleEvent: newEvent
    })

    newEvent
      ? onUpdate({ ...result, reservationDetail, actionCompleted, startDate })
      : onUpdate({ ...result, reservationDetail: undefined, actionCompleted: false, startDate })
  }

  const setBookingWindow = (bookingWindow: BookingWindow | undefined) => onUpdate({ ...result, bookingWindow })
  const setTimeAllowed = (timeAllowed: TimeAllowed | undefined) => onUpdate({ ...result, timeAllowed })
  const setVaccinatedTech = (vaccinatedTech: boolean | undefined) => onUpdate({ ...result, vaccinatedTech })
  const setScheduleFlexibility = (scheduleFlexibility: ScheduleFlexibility | undefined) =>
    onUpdate({ ...result, scheduleFlexibility })

  const userFragment = useAuthenticated().userFragment
  const developerFeatures = userFragment.permissions?.developerFeatures === true
  const isPriority = requirements?.priority?.required === PriorityType.P1

  const priorityLinkParams = !!developerFeatures && isPriority ? `&showPriority=1` : null

  const technicalAttributes = requirements?.attributes ?? []
  const skillTechAttributeLinkParams =
    technicalAttributes?.length > 0 ? '' + technicalAttributes.map((a) => `&st_${a.attributeId}=100`).join('') : ''


  const { tradeId, topicId, subtopicId, serviceTypeId } = assignment ?? {}
  const scheduleLink = !assignment ? `/schedule?onlyAvailable=1&trade=${trade}&customerType=${customerType}${vaccinatedTech ? '&onlyVaccinated=1' : ''}${skillTechAttributeLinkParams}${priorityLinkParams ? priorityLinkParams : ''}` :
    `/schedule?scheduleFilterVersion=1&onlyAvailable=1&tradeId=${tradeId}&serviceTypeId=${serviceTypeId}&topicId=${topicId}&subtopicId=${subtopicId}&${vaccinatedTech ? '&onlyVaccinated=1' : ''}}`
  console.log('scheduleLink', scheduleLink)
  const scheduledFrom =
    DateTime.fromISO(reservedScheduleEvent?.scheduled.from).setZone(dateConfig.defaultTimezone) || now

  const clearResult = () => onUpdate({ ...result, actionCompleted: false })

  const handleChangeDelayReservation = (enabled: boolean) => {
    if (!enabled) {
      setDelayedJobStatus(defaultDelayedJobStatus)
    } else {
      setDelayedJobStatus(undefined)
      onSetReservedScheduleEvent(undefined)
    }
    setReserveNow(enabled)
  }

  const updateTimeAllowed = (id: string) => setTimeAllowed(TimeAllowedOptions.find((t) => t.id === id))

  const timeWindow = getTimeWindow(bookingWindow.id as LegacyBookingWindow, scheduledFrom, {
    from: reservedScheduleEvent?.scheduled.from,
    to: reservedScheduleEvent?.scheduled.to
  })

  const updateBookingWindow = (id: string) => {
    const bookingWindow = find(bookingWindowOptions, { id }) as BookingWindow
    if (bookingWindow.startTime && startDate) {
      const newDate = DateTime.fromISO(startDate, { zone: dateConfig.defaultTimezone })
      const [hour, minute] = bookingWindow.startTime.split(':').map((el) => parseInt(el))
      newDate.set({ hour, minute })
      onUpdate({ ...result, bookingWindow, startDate: newDate.toISO() })
    } else {
      onUpdate({ ...result, bookingWindow })
    }
  }

  const filteredAnyBookingWindows = (): EuiRadioGroupOption[] => {
    if (reservedScheduleEvent) {
      const filteredBookingWindowOptions = filterBookingWindowOptions(reservedScheduleEvent.scheduled)
      if (
        bookingWindow.id !== DefaultBookingWindow.id &&
        filteredBookingWindowOptions.findIndex((bw) => bw.id === bookingWindow.id) < 0
      ) {
        setBookingWindow(DefaultBookingWindow)
      }
      return filteredBookingWindowOptions
        .filter((option) => {
          // hack to remove callout option from list until we change the legacybooking enum
          const match = option?.label.match(/After hours \(Callout\)/)
          return match === null || match.length === 0
        })
        .map((bw) => ({ id: bw.id, label: bw.label, className: bw.className }))
    } else {
      const filteredBookingWindowOptions = bookingWindowOptions
        .filter((bw) => {
          const dayOfWeek = DateTime.fromISO(startDate, { zone: dateConfig.defaultTimezone }).get('weekday')
          return !bw.weekdays || includes(bw.weekdays, dayOfWeek)
        })
        .map((bw) => ({ id: bw.id, label: bw.label, className: bw.className }))
      if (
        bookingWindow.id !== DefaultBookingWindow.id &&
        filteredBookingWindowOptions.findIndex((bw) => bw.id === bookingWindow.id) < 0
      ) {
        setBookingWindow(DefaultBookingWindow)
      }
      return filteredBookingWindowOptions
    }
  }

  const showVaccinatedTechOption = true

  //flexibility
  const showFlexibilityOptions = true
  //2 options only
  const scheduleFlexibilityOptions = [ScheduleFlexibility.Inflexible, ScheduleFlexibility.Flexible].map((k) => {
    return { id: k, label: k === ScheduleFlexibility.Inflexible ? 'Not flexible' : decamelise(k) }
  })
  const updateFlexibility = (id: any) => {
    setScheduleFlexibility(id)
  }

  // const updatePriority = (id: PriorityTypeKey) => {
  //   setPriority(id)
  // }

  interface RenderReservedEventStringOpts {
    includePrefix?: boolean
    includeLaneName?: boolean
  }

  const renderReservedEventString = (opts?: RenderReservedEventStringOpts): string | undefined => {
    const includePrefix = opts?.includePrefix ?? false
    const includeLaneName = opts?.includeLaneName ?? false
    if (!timeWindow) {
      return legacyBookingWindowLabel(bookingWindow.id as LegacyBookingWindow, timeWindow)
    }
    const today = DateTime.now().setZone(dateConfig.defaultTimezone)
    const from = DateTime.fromISO(timeWindow.from ?? reservedScheduleEvent?.scheduled.from, {
      zone: dateConfig.defaultTimezone
    })
    if (!from) {
      return legacyBookingWindowLabel(bookingWindow.id as LegacyBookingWindow, timeWindow)
    }
    const laneName = reservedScheduleEvent?.technician?.contactDetail?.fullName ?? 'a queue'
    const isToday = today.hasSame(from, 'day')
    const dateString = isToday ? (includePrefix ? 'today' : 'Today') : from.toFormat(dateConfig.luxonFormat.shortDate)
    return `${includePrefix && !isToday ? 'on ' : ''}${dateString}, ${legacyBookingWindowLabel(
      bookingWindow.id as LegacyBookingWindow,
      timeWindow
    )}${includeLaneName ? ` booked on ${laneName}` : ''}`
  }

  const renderValue = () => {
    if (!actionCompleted) {
      return undefined
    }
    return reservedScheduleEvent
      ? renderReservedEventString({ includeLaneName: true })
      : `${decamelise(delayedJobStatus)}`
  }

  const validateForm = (): boolean => {
    if (reserveNow) {
      return !!reservedScheduleEvent
    } else {
      return !!delayedJobStatus
    }
  }

  const handleOnNext = () => {
    if (validateForm()) {
      const reservationDetail = createReservationDetail({
        reservedScheduleEvent
      })
      onUpdate({ ...result, reservationDetail, actionCompleted: true })
    }
  }

  const createReservationDetail = ({ reservedScheduleEvent }: any): ReservationDetail => {
    const now = DateTime.now().setZone(dateConfig.defaultTimezone)
    const scheduledFrom =
      DateTime.fromISO(reservedScheduleEvent?.scheduled.from).setZone(dateConfig.defaultTimezone) || now
    const timeWindow = getTimeWindow(bookingWindow?.id as LegacyBookingWindow, scheduledFrom, {
      from: reservedScheduleEvent?.scheduled.from,
      to: reservedScheduleEvent?.scheduled.to
    })
    const technicianName = reservedScheduleEvent?.technician?.contactDetail?.fullName
    const reservedScheduleEventId = reservedScheduleEvent?.id
    return {
      timeWindow,
      reserveNow,
      delayedJobStatus,
      reservedScheduleEventId,
      reservedScheduleEvent,
      startDate,
      technicianName,
      bookingWindow,
      timeAllowed,
      // attributeRequirements: requirements?.attributes,
      flexibility: {
        schedule: scheduleFlexibility
      },
      membershipJob: undefined,
      vaccinatedTech
      // priority: undefined
    }
  }

  const value = renderValue()

  // Ensure that a change in reserved event caused by subscription
  // (eg. delete) will unset actionCompleted
  useEffect(() => {
    if (actionCompleted && reserveNow && !reservedScheduleEvent) {
      notificationContext.createToast({
        id: uuidv4(),
        title: 'Reserved event removed',
        text: 'The reserved event has either expired or been deleted. Please create another event and reserve again.',
        color: 'danger',
        toastLifeTimeMs: 20000
      })
    }
  }, [reservedScheduleEvent, actionCompleted, reserveNow])

  const bookingWindowDate = useMemo(() => {
    if (!reservedScheduleEvent?.scheduled?.from) {
      return ''
    }
    const date = DateTime.fromISO(reservedScheduleEvent.scheduled.from)
    return date.toFormat('ccc LLLL dd')
  }, [reservedScheduleEvent])
  return (
    <>
      <WorkflowAction
        title="Schedule reservation"
        value={value}
        onClickChange={clearResult}
        editable={input.editable}
        hideChildrenOnComplete={false}
      >
        {!actionCompleted && (
          <>
            <ScheduleReservationDisclaimers
              customerContact={customerContact}
              jobClassification={jobClassification}
              serviceArea={serviceArea}
              jobTopic={jobTopic}
            />
            <EuiFlexGroup>
              <EuiFlexItem grow={true}>
                <EuiSwitch
                  onChange={(e) => handleChangeDelayReservation(e.target.checked)}
                  label="Reserve now"
                  checked={reserveNow ?? false}
                />
              </EuiFlexItem>
              {reserveNow && showVaccinatedTechOption && (
                <EuiFlexItem grow={false}>
                  <EuiSwitch
                    label="Vaccinated technician (requested by customer)"
                    checked={vaccinatedTech}
                    onChange={() => {
                      setVaccinatedTech(!vaccinatedTech)
                    }}
                  />
                  <EuiSpacer />
                </EuiFlexItem>
              )}
            </EuiFlexGroup>

            <EuiSpacer />

            {suburbId && trade ? (
              <ServiceAreaServiceCheck trade={trade} suburbId={suburbId} companyId={companyId} />
            ) : (
              <Callout
                type="warning"
                title={`Unable to identify suburb to confirm service coverage for ${trade ?? 'job'}`}
              />
            )}

            {reserveNow && (
              <>
                <Callout type="script">
                  <EuiText>
                    <p>So, I'm just scanning for the next available technician, to resolve/tend to this is for you.</p>
                  </EuiText>
                </Callout>
                {defaultTimeAllowedForJob.timeInHours !== 2 && (
                  <Callout type="warning">
                    <EuiText>
                      Important: Please book a <strong>{defaultTimeAllowedForJob.timeInHours} hour slot</strong> for
                      this job
                    </EuiText>
                  </Callout>
                )}
                <EuiSpacer />
              </>
            )}

            {topic && (
              <>
                <JobRequirementsPreview timeAllowed={defaultTimeAllowedForJob} topic={topic} />
                <EuiSpacer />
              </>
            )}

            {!reserveNow && (
              <EuiFlexGroup>
                <EuiFlexItem grow={false} style={{ minWidth: '180px' }}>
                  <EuiTitle size="xs">
                    <h3>Job status:</h3>
                  </EuiTitle>
                  <EuiSpacer size="s" />
                  <EuiRadioGroup
                    options={jobStatusOptions.map((t) => ({ id: t.id, label: t.label }))}
                    idSelected={delayedJobStatus || defaultDelayedJobStatus}
                    onChange={(value) => setDelayedJobStatus(value as JobStatusType)}
                    name="delayedJobStatus"
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            )}
          </>
        )}

        {reserveNow && (
          <>
            <ReservedScheduleEvents
              onSelect={onSetReservedScheduleEvent}
              scheduleLink={scheduleLink}
              isVisible={!actionCompleted}
            />
            {!actionCompleted && reservedScheduleEvent && (
              <>
                <EuiFlexGroup alignItems="flexStart">
                  <EuiFlexItem grow={false} style={{ minWidth: '180px' }}>
                    <EuiTitle size="xs">
                      <h3>Time window</h3>
                    </EuiTitle>
                    <EuiSpacer size="s" />
                    <EuiRadioGroup
                      options={filteredAnyBookingWindows()}
                      idSelected={bookingWindow.id}
                      onChange={updateBookingWindow}
                      name="bookingWindow"
                    />
                  </EuiFlexItem>
                  <EuiFlexItem grow={false} style={{ minWidth: '180px' }}>
                    <EuiTitle size="xs">
                      <h3>Time allowed</h3>
                    </EuiTitle>
                    <EuiSpacer size="s" />
                    <EuiRadioGroup
                      options={TimeAllowedOptions.map((t) => ({ id: t.id, label: t.label }))}
                      idSelected={timeAllowed.id}
                      onChange={updateTimeAllowed}
                      name="timeAllowed"
                    />
                  </EuiFlexItem>

                  {showFlexibilityOptions && (
                    <>
                      <EuiFlexItem grow={false} style={{ minWidth: '180px' }}>
                        <EuiTitle size="xs">
                          <h3>General availability</h3>
                        </EuiTitle>
                        <EuiSpacer size="s" />
                        <EuiRadioGroup
                          options={scheduleFlexibilityOptions}
                          idSelected={scheduleFlexibility}
                          onChange={updateFlexibility}
                        />
                      </EuiFlexItem>

                      {/* {showFlexibilityDirectionOptions && (
                  <EuiFlexItem grow={false} style={{ minWidth: '180px' }}>
                    <EuiTitle size="xs">
                      <h3>Preference for moving the appointment?</h3>
                    </EuiTitle>
                    <EuiSpacer size="s" />
                    <EuiRadioGroup
                      options={scheduleFlexibilityDirectionOptions}
                      idSelected={scheduleFlexibilityDirection}
                      onChange={updateFlexibilityDirection}
                    />
                  </EuiFlexItem>
                )} */}
                    </>
                  )}
                </EuiFlexGroup>
              </>
            )}
          </>
        )}

        {!actionCompleted && (
          <>
            {reservedScheduleEvent && timeWindow && (
              <>
                <EuiSpacer />
                <Callout type="note">
                  <EuiText>Must be framed up positively: MUST READ ONE VERBATIM:</EuiText>
                </Callout>
                <Callout type="script">
                  <EuiText>
                    <ul>
                      <li>
                        <EuiText>
                          Good news/happy to report/you’re in luck…I can get a tech out to you on{' '}
                          <strong>{bookingWindowDate}</strong>
                        </EuiText>
                      </li>
                      <li>
                        <EuiText>
                          Good new, even though we’re very busy, we’re able to get you in on{' '}
                          <strong>{bookingWindowDate}</strong>
                        </EuiText>
                      </li>
                    </ul>
                  </EuiText>
                </Callout>
              </>
            )}
            <EuiSpacer size="s" />
            <EuiButton disabled={!validateForm()} onClick={handleOnNext}>
              Next
            </EuiButton>
            <EuiSpacer />
          </>
        )}
      </WorkflowAction>
    </>
  )
}

const legacyBookingWindowLabel = (
  bookingWindow: LegacyBookingWindow | undefined,
  timeWindow: TimeWindow | undefined
): string => {
  const from = timeWindow?.from
    ? dateUtils.fromISO(timeWindow.from).toFormat(dateConfig.luxonFormat.time).toLowerCase()
    : undefined
  const to = timeWindow?.to
    ? dateUtils.fromISO(timeWindow.to).toFormat(dateConfig.luxonFormat.time).toLowerCase()
    : undefined
  switch (bookingWindow) {
    case LegacyBookingWindow.Guarantee7:
      return 'arriving at 7am'
    case LegacyBookingWindow.Window7to8:
      return 'arriving between 7 - 8am'
    case LegacyBookingWindow.Window8to11:
      return 'arriving between 8am - 11am'
    case LegacyBookingWindow.Window10to13:
      return 'arriving between 10am - 1pm'
    case LegacyBookingWindow.Window10to14:
      return 'arriving between 10am - 2pm'
    case LegacyBookingWindow.Window12to16:
      return 'arriving between 12pm - 4pm'
    case LegacyBookingWindow.ToBeAdvised:
      return `to be advised (${from} - ${to})`
    case LegacyBookingWindow.AfterHours:
      return `after hours (${from} - ${to})`
    case LegacyBookingWindow.Project:
      return `project (${from} - ${to})`
    default:
      return 'unknown'
  }
}
