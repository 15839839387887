import {
  EuiButtonIcon,
  EuiCallOut,
  EuiContextMenu,
  EuiContextMenuPanelDescriptor,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHealth,
  EuiLink,
  EuiPanel,
  EuiPopover,
  EuiSpacer
} from '@elastic/eui'
import { MoneyUtils } from '@fallonsolutions/money'
import { isNil } from 'lodash-es'
import { DateTime } from 'luxon'
import { ReactNode, useEffect, useState } from 'react'
import {
  Customer,
  CustomerFragment,
  CustomerSource,
  CustomerType,
  DataQualityStatus,
  HoldStatus,
  PaymentTerms,
  TagTarget
} from '../api/generated-types'
import { useAuthenticated } from '../auth/authenticated-context'
import { dateConfig } from '../common/date-config-luxon'
import { DescriptionList } from '../common/description-list'
import { decamelise } from '../common/utils'
import VerticalDivider from '../common/vertical-divider'
import { DataQualityStatusBadge } from '../data-quality/data-quality-status-badge'
import { HistoryItemType, usePlatformHistory } from '../history/history-item'
import { myobCustomerLink } from '../myob/myob-links'
import myobIcon from '../static/images/myob-icon.png'
import { TagList } from '../tags/tag-list'
import { CompanyEdit } from './customer-company-edit'
import { CustomerContactsView } from './customer-contacts-view'
import { CustomerEdit } from './customer-details-edit'
import { CustomerMergeStatusView } from './customer-merge-status-callout'
import { CustomerNotesView } from './customer-notes'
import { UpdateCustomerDataQualityForm } from './update-customer-data-quality-form'

interface CustomerDetailProps {
  customer: CustomerFragment
}

export const CustomerDetail = (props: CustomerDetailProps) => {
  const { customer } = props

  const { userFragment } = useAuthenticated()
  const { addHistoryItem } = usePlatformHistory()

  useEffect(() => {
    const label = `${customer.number ?? 'Customer'}: ${customer.mainContact?.detail?.fullName ?? ''}`
    addHistoryItem({ id: customer.id, label, type: HistoryItemType.Customer })
  }, [customer.id, customer.number])

  const [isCustomerDetailsMenuOpen, setIsCustomerDetailsMenuOpen] = useState(false)
  const [isCompanyDetailsMenuOpen, setIsCompanyDetailsMenuOpen] = useState(false)

  const [isCompanyDetailsModalVisible, setIsCompanyDetailsModalVisible] = useState(false)
  const [isCustomerDetailsModalVisible, setIsCustomerDetailsModalVisible] = useState(false)
  const [isUpdateCustomerDataQualityVisible, setIsUpdateCustomerDataQualityVisible] = useState(false)

  const companyAttributes: Array<{
    title: NonNullable<ReactNode>
    description: NonNullable<ReactNode>
  }> = [
    { title: 'Company', description: customer.company?.name || '-' },
    { title: 'ABN', description: customer.company?.abn || '-' },
    {
      title: 'Web',
      description: customer.company?.web ? (
        <EuiLink href={customer.company.web} target={'_blank'} rel={'noopener noreferrer'}>
          {customer.company.web}
        </EuiLink>
      ) : (
        '-'
      )
    }
  ]

  const canEditDetails =
    userFragment.permissions?.customerFinance === true || userFragment.permissions?.editCustomerDetails === true

  const canEditCompanyDetails = canEditDetails && (!customer.type || customer.type === CustomerType.Commercial)

  const canEditCustomerDetails = canEditDetails

  // TODO: separate editing of the finance fields (hold status, writeOffLimit, creditLimit etc)
  //  const canEditCustomerFinanceDetails = userFragment.permissions?.customerFinance === true

  const companyDetailsMenu = [
    {
      id: 0,
      items: [
        {
          name: 'Edit company details',
          icon: 'documentEdit',
          disabled: !canEditCompanyDetails,
          toolTipContent: canEditCompanyDetails ? undefined : 'You need finance permission to edit company details',
          onClick: () => {
            setIsCompanyDetailsMenuOpen(false)
            setIsCompanyDetailsModalVisible(true)
          }
        }
      ]
    }
  ] as EuiContextMenuPanelDescriptor[]

  const customerDetailsMenu = [
    {
      id: 0,
      items: [
        {
          name: 'Edit customer details',
          icon: 'documentEdit',
          disabled: !canEditCustomerDetails,
          toolTipContent: canEditCustomerDetails ? undefined : 'You need finance permission to edit customer details',
          onClick: () => {
            setIsCustomerDetailsMenuOpen(false)
            setIsCustomerDetailsModalVisible(true)
          }
        },
        ...(canEditCompanyDetails && !customer.company
          ? [
              {
                name: 'Add company details',
                icon: 'plusInCircle',
                onClick: () => {
                  setIsCustomerDetailsMenuOpen(false)
                  setIsCompanyDetailsModalVisible(true)
                }
              }
            ]
          : []),
        {
          isSeparator: true
        },
        {
          name: 'View in MYOB',
          icon: myobIcon,
          target: '_blank',
          href: myobCustomerLink(customer),
          rel: 'noopener noreferrer',
          onClick: () => setIsCustomerDetailsMenuOpen(false)
        },
        {
          name: 'Edit customer data quality',
          icon: 'check',
          onClick: () => {
            setIsUpdateCustomerDataQualityVisible(true)
            setIsCustomerDetailsMenuOpen(false)
          }
        }
      ]
    }
  ] as EuiContextMenuPanelDescriptor[]

  return (
    <div>
      {isCompanyDetailsModalVisible && (
        <CompanyEdit customer={props.customer} closeModal={() => setIsCompanyDetailsModalVisible(false)} />
      )}
      {isCustomerDetailsModalVisible && (
        <CustomerEdit
          customer={props.customer as Customer}
          closeModal={() => setIsCustomerDetailsModalVisible(false)}
        />
      )}

      <EuiPanel data-test-id="customer-detail-top-container">
        {customer.mergeStatus && (
          <>
            <CustomerMergeStatusView customer={customer} />
            <EuiSpacer />
          </>
        )}

        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <div className="small-label">Reference</div>
            <span title={customer.number || 'No reference'}>
              <strong className="truncate" style={{ maxWidth: '120px', display: 'block' }}>
                {customer.number || 'No reference'}
              </strong>
            </span>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <div className="small-label">Type</div>
            <span>{customer.type ? <strong>{customer.type}</strong> : 'Not set'}</span>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <VerticalDivider height="48px" />
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <div className="small-label">Status</div>
            <span>
              <strong>
                {customer.active === false ? (
                  <EuiHealth textSize="xs" color="danger">
                    Inactive
                  </EuiHealth>
                ) : (
                  <EuiHealth textSize="xs" color="success">
                    Active
                  </EuiHealth>
                )}
              </strong>
            </span>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <div className="small-label">Hold status</div>
            <span>
              <strong>
                {[HoldStatus.CreditHold, HoldStatus.OnHold].includes(customer.holdStatus ?? HoldStatus.None) ? (
                  <EuiHealth textSize="xs" color="danger">
                    {decamelise(customer.holdStatus)}
                  </EuiHealth>
                ) : (
                  <EuiHealth textSize="xs" color="success">
                    None
                  </EuiHealth>
                )}
              </strong>
            </span>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <div className="small-label">Data quality</div>
            <span>
              <DataQualityStatusBadge status={customer.dataQuality?.status ?? DataQualityStatus.NotChecked} />
            </span>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <div className="small-label">Terms</div>
            <span>
              <strong>{decamelise(customer.terms ?? PaymentTerms.CashOnDelivery)}</strong>
            </span>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <VerticalDivider height="48px" />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <div className="small-label">Credit Limit</div>
            <span>
              {customer.creditLimit?.amount ? <strong>{formatMoney(customer.creditLimit.amount)}</strong> : 'Not set'}
            </span>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <div className="small-label">Write-off Limit</div>
            <span>
              {customer.writeOffLimit?.amount ? (
                <strong>{formatMoney(customer.writeOffLimit.amount)}</strong>
              ) : (
                'Not set'
              )}
            </span>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <VerticalDivider height="48px" />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <TagList
              tags={customer.tags ?? []}
              kind={TagTarget.Customer}
              objectIdentifier={customer.mainContact?.detail?.firstName ?? ''}
              objectId={customer.id}
            />
          </EuiFlexItem>
          <EuiFlexItem grow={true} />
          <EuiFlexItem grow={false}>
            <EuiPopover
              isOpen={isCustomerDetailsMenuOpen}
              button={
                <EuiButtonIcon
                  aria-label="show actions"
                  iconType="boxesHorizontal"
                  color="text"
                  onClick={() => setIsCustomerDetailsMenuOpen(!isCustomerDetailsMenuOpen)}
                />
              }
              closePopover={() => setIsCustomerDetailsMenuOpen(false)}
              ownFocus={true}
              panelPaddingSize="none"
            >
              <EuiContextMenu initialPanelId={0} panels={customerDetailsMenu} />
            </EuiPopover>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <div className="small-label">Source</div>
            <span>{customer.source === CustomerSource.Platform ? 'Platform' : 'Axia'}</span>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <div className="small-label">Customer since</div>
            <span>{customer.created ? DateTime.fromISO(customer.created).toFormat(dateConfig.fullDate) : ''}</span>
          </EuiFlexItem>
          <EuiFlexItem grow={true}>
            <div className="small-label">Acquisition Channel</div>
            <span>{!isNil(customer.acquisitionChannel) ? customer.acquisitionChannel : 'Unknown'}</span>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexGroup>
          <EuiFlexItem grow={true}>
            {customer.source !== CustomerSource.Platform && (
              <EuiCallOut
                iconType="alert"
                color="warning"
                title="Axia customer - please edit customer details in Axia"
                size="s"
              />
            )}
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiSpacer size="s" />

        <CustomerNotesView customer={customer} />
      </EuiPanel>

      {(!customer.type || customer.type === CustomerType.Commercial) && customer.company && (
        <>
          <EuiSpacer size="l" />
          <EuiPanel>
            <EuiFlexGroup>
              <EuiFlexItem grow={true}>
                <DescriptionList attributes={companyAttributes} columns={3} />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiPopover
                  isOpen={isCompanyDetailsMenuOpen}
                  button={
                    <EuiButtonIcon
                      aria-label="show actions"
                      iconType="boxesHorizontal"
                      color="text"
                      onClick={() => setIsCompanyDetailsMenuOpen(!isCompanyDetailsMenuOpen)}
                    />
                  }
                  closePopover={() => setIsCompanyDetailsMenuOpen(false)}
                  ownFocus={true}
                  panelPaddingSize="none"
                >
                  <EuiContextMenu initialPanelId={0} panels={companyDetailsMenu} />
                </EuiPopover>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>
        </>
      )}
      <EuiSpacer />

      <CustomerContactsView customer={customer} />
      <EuiSpacer />

      {isUpdateCustomerDataQualityVisible && (
        <UpdateCustomerDataQualityForm
          customerId={customer.id}
          onClose={() => setIsUpdateCustomerDataQualityVisible(false)}
        />
      )}
    </div>
  )
}

const formatMoney = (amount: string): string => {
  const money = MoneyUtils.fromString(amount).setLocale('en-AU')
  return money.toFormat('$0,0.00')
}
