import { EuiToolTip } from '@elastic/eui'
import { TagFragment } from '@fallonsolutions/types'
import { MouseEventHandler, useState } from 'react'
import { Tag, TagSize } from './tag'
import { TagContextMenu } from './tag-context-menu'

interface TagViewProps {
  tag: TagFragment
  removeTag?: () => void
  loading: boolean
  size?: TagSize
}
export const TagView = ({ tag, removeTag, loading, size = TagSize.Default }: TagViewProps) => {
  const [isContextMenuVisible, setIsContextMenuVisible] = useState(false)

  const handleContextMenuClick: MouseEventHandler<any> = (event) => {
    event.preventDefault()
    setIsContextMenuVisible(true)
  }

  return (
    <>
      {removeTag ? (
        <>
          <TagContextMenu
            onClose={() => setIsContextMenuVisible(false)}
            visible={isContextMenuVisible}
            loading={loading}
            removeTag={removeTag}
          >
            <div onContextMenu={handleContextMenuClick}>
              <EuiToolTip content={tag.description} position="top">
                <Tag tag={tag} size={size} />
              </EuiToolTip>
            </div>
          </TagContextMenu>
        </>
      ) : (
        <>
          <EuiToolTip content={tag.description} position="top">
            <Tag tag={tag} size={size} />
          </EuiToolTip>
        </>
      )}
    </>
  )
}
