import { EuiSpacer } from '@elastic/eui'
import { TradeSubmitWorkflowStepDetailInput } from '@fallonsolutions/types'
import { TradeWorkflowStepDetailFragment } from '../../api/generated-types'
import { Callout } from '../../common/callout'
import { ListNav } from '../../common/list-nav'
import { ListNavOption } from '../../common/list-nav-option'
import { WorkflowV2StepProps } from './workflow-v2-step-interface'

export interface TradeWorkflowV2StepProps extends WorkflowV2StepProps {
  detail: TradeWorkflowStepDetailFragment
  result: Partial<TradeSubmitWorkflowStepDetailInput>
  onChange: (result: TradeSubmitWorkflowStepDetailInput) => void
}

export const TradeWorkflowV2Step = ({ result, onChange, detail }: TradeWorkflowV2StepProps) => {
  const choiceId = result.choiceId

  const options: ListNavOption[] = detail.choices.map((choice) => ({
    value: choice.id,
    label: choice.label
    // icon: choice.iconUrl
  }))
  const selected = options.find((o) => o.value === choiceId)

  return (
    <>
      <Callout type="script">{detail.question}</Callout>
      <EuiSpacer size="s" />
      <ListNav value={selected} options={options} onChange={(option) => onChange({ choiceId: option.value })} />
    </>
  )
}
