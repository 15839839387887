import { useQuery } from '@apollo/client'
import { SearchTagsDocument, TagTarget } from '../api/generated-types'
import { useState } from 'react'
import { EuiAccordion, EuiCheckboxGroup, EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiText } from '@elastic/eui'
import '../static/css/action-create-job-contact-nomination.css'
import { TagView } from '../tags/tag-view'

type CheckboxIdToSelectedMap = {
  [key: string]: boolean
}

export interface JobTagsProps {
  tags: string[]
  setTags: (tags: string[]) => void
}
export const JobTags = (props: JobTagsProps) => {
  const { tags: tagIds, setTags } = props

  const { data, loading } = useQuery(SearchTagsDocument, {
    variables: {
      input: {
        filter: {
          targets: [TagTarget.Job]
        }
      }
    }
  })

  const [selectedTags, setSelectedTags] = useState<CheckboxIdToSelectedMap>({})

  const jobTags = data?.searchTags?.results ?? []

  const buttonContent = () => {
    if (tagIds.length === 0) {
      return (
        <>
          <EuiText size="m">
            <strong>No tags selected</strong>
          </EuiText>
        </>
      )
    }

    return (
      <>
        <EuiFlexGroup direction="row" gutterSize="s">
          <EuiFlexItem grow={false}>
            <EuiText size="m">
              <strong>Selected tags:</strong>
            </EuiText>
          </EuiFlexItem>
          {tagIds.map((tagId) => {
            const tag = jobTags.find((tag) => tag.id === tagId)
            if (!tag) {
              return null
            }
            return (
              <EuiFlexItem grow={false} key={tagId}>
                <EuiText key={tagId} size="m">
                  <TagView tag={tag} loading={loading} />
                </EuiText>
              </EuiFlexItem>
            )
          })}
        </EuiFlexGroup>
      </>
    )
  }

  const checkboxOptions = jobTags.map((tag) => {
    return {
      id: tag.id,
      label: <TagView tag={tag} loading={loading} />
    }
  })

  const onChange = (tagId: string) => {
    const newCheckboxIdToSelectedMap = {
      ...selectedTags,
      ...{
        [tagId]: !selectedTags[tagId]
      }
    }
    setSelectedTags(newCheckboxIdToSelectedMap)
    setTags(Object.keys(newCheckboxIdToSelectedMap).filter((tagId) => newCheckboxIdToSelectedMap[tagId]))
  }

  return (
    <>
      <EuiAccordion
        id="job-tag-accordion"
        buttonContent={buttonContent()}
        arrowDisplay="right"
        className="customer-primary-contact"
      >
        <EuiSpacer size="m" />
        <EuiCheckboxGroup
          options={checkboxOptions}
          onChange={(tagId) => onChange(tagId)}
          idToSelectedMap={selectedTags}
        />
      </EuiAccordion>
    </>
  )
}
