import { ApolloError, useMutation } from '@apollo/client'
import {
  EuiButton,
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiModal,
  EuiSpacer,
  EuiTextArea,
  EuiTitle
} from '@elastic/eui'
import { getTimeWindow } from '@fallonsolutions/appointment'
import { dateUtils } from '@fallonsolutions/date'
import { getPriorities } from '@fallonsolutions/priority'
import moment from 'moment-timezone'
import { ReactElement, useEffect, useState } from 'react'
import {
  AfterHours,
  AssignAppointmentToScheduleEventDocument,
  AssignAppointmentToScheduleEventInput,
  AttributeRequirement,
  CommunicationChannelSelection,
  ContactCustomerRole,
  CreateAppointmentDocument,
  CreateAppointmentInput,
  CreateJobDocument,
  CreateJobInput,
  CustomerContactContactFragment,
  CustomerType,
  LegacyBookingWindow,
  Membership,
  MembershipLevel,
  PriorityType
} from '../../api/generated-types'
import { CrossTradeReferrer } from '../../bookings/cross-trade-referrer'
import { BrandOptions } from '../../brand/brand-selector'
import useTextAreaHighlighter from '../../common/use-textarea-highlighter'
import { CreateContactForm } from '../../contacts/create-contact-form'
import { AllAction, useDecisionPoint } from '../../decision-point/use-decision-point'
import { HistoryItemType, usePlatformHistory } from '../../history/history-item'
import { JobSummaryContainer } from '../../jobs/job-summary-container'
import { MembershipLevelToggle } from '../../jobs/membership-level-toggle'
import { UserComboItem } from '../../users/user-combo-box'
import { PrimaryJobContact } from '../action-create-job-contact-nomination'
import { ParentJob } from '../action-create-job-link-job'
import { useWorkflow } from '../helpers/workflow-provider'
import { WorkflowAction } from '../workflow-action'
import { SelectedCustomer } from '../workflow-customer-model'
import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'
import { ContactActionResult } from './action-contact'
import { SelectedProperty } from './action-customer-property'
import { JobClassificationActionResult } from './action-job-classification-types'
import { JobCloseActionResult } from './action-job-close'
import { SelectedJob } from './action-select-job'
import { SelectJobTopicActionResult } from './action-select-job-topic'
import { BookJobProps, arePrerequisitesValid } from './are-job-pre-requisites-valid'
import { ReservationDetail } from './schedule-reservation/action-schedule-reservation'
import { JobTags } from '../action-create-job-apply-tags'
import { useAuthenticated } from '../../auth/authenticated-context'

interface SelectedAppointment {
  id: string
}

interface SelectedBrand {
  id: string
  name: string
  companyId: string
}

export interface CreateJobActionInput extends BaseActionInput {
  customer: SelectedCustomer
  contacts?: CustomerContactContactFragment[]
  contact?: ContactActionResult
  property: SelectedProperty
  isExistingMember: boolean
  jobTopic: SelectJobTopicActionResult
  jobClassification: JobClassificationActionResult
  brand?: SelectedBrand
  reservationDetail: ReservationDetail
  defaultWorkRequiredNotes: string
  defaultInternalNotes: string
  activeMembership?: Membership
  jobClose?: JobCloseActionResult
  bookingConfirmation: CommunicationChannelSelection | undefined
  existingJobId?: string | undefined
  fastTrackBookingEnabled?: boolean
}

export interface CreateJobActionResult extends BaseActionResult {
  workRequired?: string
  internalNotes?: string
  job?: SelectedJob
  appointment?: SelectedAppointment
  jobCreated?: boolean
  referrer?: UserComboItem
}

// eslint-disable-next-line max-lines-per-function,complexity
export const CreateJobAction = (props: WorkflowActionProps<CreateJobActionInput, CreateJobActionResult>) => {
  const { addHistoryItem } = usePlatformHistory()
  const { enquiryId } = useWorkflow()

  const textAreaHighlighter = useTextAreaHighlighter({ matches: ['<membership level>', '<membership expiry>'] })
  const { input, onUpdate, result } = props
  const {
    customer,
    property,
    jobTopic,
    jobClassification,
    reservationDetail,
    activeMembership,
    jobClose,
    contact,
    bookingConfirmation,
    contacts,
    existingJobId,
    fastTrackBookingEnabled
  } = input ?? {}

  const [parentJobId, setParentJobId] = useState<string | undefined>(
    fastTrackBookingEnabled ? existingJobId : undefined
  )

  const isDev = useAuthenticated().developerFeatures

  const { job, appointment, jobCreated, actionCompleted, internalNotes, workRequired, referrer } = result ?? {}
  const { topic } = jobClassification
  const [createRequestError, setCreateRequestError] = useState<ApolloError | Error | undefined>(undefined)

  const afterHours =
    (reservationDetail.bookingWindow.id as LegacyBookingWindow) === LegacyBookingWindow.AfterHours
      ? AfterHours.Booked
      : AfterHours.None

  const [membershipLevel, setMembershipLevel] = useState<MembershipLevel>(
    activeMembership
      ? (activeMembership.level ?? MembershipLevel.None)
      : afterHours !== AfterHours.None
        ? MembershipLevel.Complimentary
        : MembershipLevel.None
  )

  const [nominatedContactId, setNominatedContactId] = useState<string | undefined>(
    contact?.id ?? contacts?.find((c) => c.roles?.includes(ContactCustomerRole.Primary))?.contactId
  )

  const [jobTags, setTags] = useState<string[]>([])
  const [createNewContact, setCreateNewContact] = useState(false)

  const { canChangeEnquiryCustomerMembership } = useDecisionPoint(AllAction.ChangeEnquiryCustomerMembership)
  const canUserChangeCustomerMembership = !!canChangeEnquiryCustomerMembership().ok

  const setWorkRequired = (workRequired: string) => {
    console.log('setWorkRequired', workRequired)
    onUpdate({ ...result, workRequired })
  }
  const setInternalNotes = (internalNotes: string) => onUpdate({ ...result, internalNotes })
  const setReferrer = (referrer?: UserComboItem) => onUpdate({ ...result, referrer })

  const [createAppointment, { loading: creatingAppointment }] = useMutation(CreateAppointmentDocument)
  const [assignAppointmentToScheduleEvent, { loading: assigningAppointmentToScheduleEvent }] = useMutation(
    AssignAppointmentToScheduleEventDocument
  )
  const [createJob, { loading: creatingJob }] = useMutation(CreateJobDocument)
  const waitingForApiResponse = creatingJob || creatingAppointment || assigningAppointmentToScheduleEvent

  useEffect(() => {
    const newInternalNotes = !internalNotes && input.defaultInternalNotes ? input.defaultInternalNotes : undefined
    const newWorkRequired = !workRequired && input.defaultWorkRequiredNotes ? input.defaultWorkRequiredNotes : undefined
    if (newInternalNotes || newWorkRequired) {
      onUpdate({
        ...result,
        ...(newInternalNotes && { internalNotes: newInternalNotes }),
        ...(newWorkRequired && { workRequired: newWorkRequired })
      })
    }
  }, [])

  //todo: refactor the types to simplify the usage for the priority enum => string key and inputs
  const priorityKey = getPriorities().find((p) => p.id === topic?.requirements?.priority?.required)?.id
  //disabled in prod until comms
  const priorityRequirement = priorityKey ? { required: PriorityType[priorityKey] ?? undefined } : undefined
  const attributeRequirements = (topic?.requirements?.attributes ?? []).concat(
    reservationDetail?.vaccinatedTech ? ([{ attributeId: 'VaccinatedCovid19' }] as AttributeRequirement[]) : []
  )

  const brand: BrandOptions = {
    id: 'fallonsolutions',
    companyid: 'fallonsolutions',
    name: 'Fallon Solutions',
    label: 'Fallon Solutions',
    tenantid: 'fallonsolutions',
    active: 'true'
  }

  const bookJobProps: BookJobProps = {
    tagIds: jobTags,
    customerId: customer.id,
    propertyId: property.id,
    brand,
    parentJobId: parentJobId,
    notes: internalNotes,
    workRequired,
    trade: jobClassification.trade,
    type: jobClassification.type,
    customerType: jobClassification.customerType, //computedCustomerType,
    category: jobClassification.category,
    topic: jobTopic.topic?.reference,
    membershipLevel,
    purchaseOrderNumber: '',
    acquisitionChannel: undefined,
    startDate: moment(reservationDetail.startDate),
    bookingWindow: reservationDetail.bookingWindow,
    timeAllowed: reservationDetail.timeAllowed,
    delayedJobStatus: reservationDetail.delayedJobStatus,
    reserveNow: reservationDetail.reserveNow,
    reservedScheduleEvent: reservationDetail.reservedScheduleEvent,
    attributeRequirements,
    flexibility: reservationDetail.flexibility,
    priorityRequirement,
    membershipJob: reservationDetail.membershipJob,
    bookingConfirmation,
    contactId: nominatedContactId
  }

  const handleCreateJob = async (props: BookJobProps): Promise<SelectedJob | undefined> => {
    console.log('Creating job...')
    if (!props.customerId || !props.propertyId) {
      return undefined
    }
    const requirements = {
      attributes: props.attributeRequirements,
      flexibility: props.flexibility,
      ...(props?.priorityRequirement && { priority: { required: props.priorityRequirement.required! } })
    }
    console.log('createJobInput with enquiryId', enquiryId)
    const createJobInput: CreateJobInput = {
      enquiryId,
      customer: props.customerId,
      customerId: props.customerId,
      afterHours:
        (props.bookingWindow.id as LegacyBookingWindow) === LegacyBookingWindow.AfterHours
          ? AfterHours.Booked
          : AfterHours.None,
      property: props.propertyId,
      propertyId: props.propertyId,
      notes: props.notes ?? '',
      workRequired: props.workRequired ?? '',
      trade: props.trade,
      type: props.type,
      parentJobId: props.parentJobId,
      brand: brand.id,
      discounts: jobClose?.appliedDiscount ? [jobClose.appliedDiscount.id] : undefined,
      company: brand.companyid,
      purchaseOrderNumber: props.customerType === CustomerType.Commercial ? props.purchaseOrderNumber : undefined,
      membershipLevel,
      category: props.category,
      topic: props.topic,
      customerType: props.customerType,
      acquisitionChannel: props.acquisitionChannel,
      tagIds: props.tagIds,
      startDate: props.startDate?.toISOString(true),
      bookingWindow: props.bookingWindow.id as LegacyBookingWindow,
      timeAllowed: props.timeAllowed.timeInHours,
      contactId: props.contactId,
      bookingConfirmation: { channel: props.bookingConfirmation },
      ...(props.delayedJobStatus && { status: props.delayedJobStatus }),
      ...(requirements && { defaultAppointmentRequirements: requirements }),
      ...(referrer && { referrerId: referrer.id })
    }

    const jobResponse = await createJob({ variables: { input: createJobInput } })
    const createdJob = jobResponse.data?.createJob?.job
    return createdJob
      ? {
          id: createdJob.id,
          label: createdJob.number,
          reference: createdJob.number
        }
      : undefined
  }

  const handleCreateAppointment = async (
    props: BookJobProps,
    selectedJob: SelectedJob
  ): Promise<SelectedAppointment | undefined> => {
    if (!props.reservedScheduleEvent) {
      return undefined
    }
    const requirements = {
      attributes: props.attributeRequirements,
      flexibility: props.flexibility,
      ...(props.priorityRequirement && { priority: priorityRequirement }) //TODO hack
    }
    const scheduledFrom = dateUtils.fromISO(props.reservedScheduleEvent.scheduled.from)
    const bookingWindow = getTimeWindow(props.bookingWindow.id as LegacyBookingWindow, scheduledFrom, {
      from: props.reservedScheduleEvent.scheduled.from,
      to: props.reservedScheduleEvent.scheduled.to
    })

    // create appointment
    const createAppointmentInput: CreateAppointmentInput = {
      jobId: selectedJob.id,
      legacyBookingWindow: props.bookingWindow.id as LegacyBookingWindow,
      bookingWindow,
      requirements,
      jobType: props.type
    }
    console.log('Creating appointment...', createAppointmentInput)
    const appointmentResponse = await createAppointment({ variables: { input: createAppointmentInput } })
    const newAppointmentId = appointmentResponse.data?.createAppointment.appointment?.id
    console.log('Appointment created', newAppointmentId)
    return newAppointmentId ? { id: newAppointmentId } : undefined
  }

  const handleAssignAppointmentToScheduleEvent = async (
    props: BookJobProps,
    selectedAppointment: SelectedAppointment
  ): Promise<ReactElement | undefined> => {
    if (!props.reservedScheduleEvent) {
      return undefined
    }
    // link appointment with the reserved schedule event
    const assignAppointmentToScheduleEventInput: AssignAppointmentToScheduleEventInput = {
      id: props.reservedScheduleEvent.id,
      appointment: selectedAppointment.id,
      ...(bookingConfirmation && {
        bookingConfirmation: {
          channel: bookingConfirmation
        }
      })
    }
    console.log('Assigning appointment to reserved schedule event...')
    const response = await assignAppointmentToScheduleEvent({
      variables: { input: assignAppointmentToScheduleEventInput }
    })
    console.log('response', response.data, response.errors)
    console.log('Reserved schedule event assigned to appointment.')
  }

  const bookJob = async (props: BookJobProps) => {
    // set action complete on end
    if (arePrerequisitesValid(props)) {
      let selectedJob: SelectedJob | undefined = undefined
      let selectedAppointment: SelectedAppointment | undefined = undefined
      const expiryRegex = new RegExp('expiry: (\\d{2}-\\d{2}-\\d{4})', 'i')
      const membershipRegex = new RegExp('Current: (red|black)', 'i')
      props.notes = props.notes
        ?.replace(expiryRegex, 'Expiry: **$1**')
        .replace(membershipRegex, 'Membership level: **$1**')
      try {
        console.log('Booking job...')
        selectedJob = job ?? (await handleCreateJob(props))
        if (!selectedJob) {
          throw new Error('error creating job')
        }
        if (props.reservedScheduleEvent && selectedJob) {
          selectedAppointment = appointment ?? (await handleCreateAppointment(props, selectedJob))
          if (!selectedAppointment) {
            throw new Error('error creating appointment')
          }
          await handleAssignAppointmentToScheduleEvent(props, selectedAppointment)
        }
        console.log('newJobId', selectedJob?.id)
        if (selectedJob) {
          addHistoryItem({
            id: selectedJob.id,
            type: HistoryItemType.Job,
            label: selectedJob.reference
          })
        }
        if (customer) {
          addHistoryItem({
            id: customer.id,
            type: HistoryItemType.Customer,
            label: customer.reference ?? 'Customer'
          })
        }
        onUpdate({ ...result, job: selectedJob, appointment: selectedAppointment, jobCreated: true })
      } catch (e: any) {
        console.log('createJob mutation error', e?.message)
        onUpdate({ ...result, job: selectedJob, appointment: selectedAppointment, jobCreated: false })
        setCreateRequestError(e)
      }
    }
  }

  const onContinue = () => onUpdate({ ...result, actionCompleted: true })
  const clearResult = () => onUpdate({ ...result, actionCompleted: false })

  const isValid =
    !!reservationDetail && (workRequired ?? '').trim().length > 0 && (internalNotes ?? '').trim().length > 0

  return (
    <WorkflowAction
      title="Create job"
      value={actionCompleted && job ? job?.label : undefined}
      onClickChange={clearResult}
      editable={input.editable}
    >
      {job && jobCreated ? (
        <>
          <div className="workflow__detail-wrapper">
            <EuiTitle size="s">
              <h2>Job {job?.reference} created successfully</h2>
            </EuiTitle>
            <EuiSpacer size="xs" />
            <JobSummaryContainer jobId={job.id} />
          </div>
          <EuiSpacer />
          <EuiButton color="primary" onClick={onContinue}>
            Next
          </EuiButton>
        </>
      ) : (
        <>
          {createNewContact && (
            <EuiModal onClose={() => setCreateNewContact(false)}>
              <CreateContactForm customer={customer} onClose={() => setCreateNewContact(false)} />
            </EuiModal>
          )}
          {jobClassification.customerType === CustomerType.Domestic && (
            <>
              <EuiFormRow label="Confirm current membership status" fullWidth>
                <MembershipLevelToggle
                  editable={true}
                  membershipLevel={membershipLevel}
                  onChange={setMembershipLevel}
                  disableSelection={!canUserChangeCustomerMembership}
                />
              </EuiFormRow>
              <EuiSpacer size="m" />
            </>
          )}

          <>
            <PrimaryJobContact
              contacts={contacts ?? []}
              onChange={setNominatedContactId}
              selectedContactId={nominatedContactId}
              onCreateNewContact={() => setCreateNewContact(true)}
            />
            {nominatedContactId && contact && nominatedContactId !== contact.id && (
              <EuiCallOut title="Call contact differs from job contact" color="danger">
                <p>Please check the call contact and nominated job contact details and update if this is in error.</p>
              </EuiCallOut>
            )}

            <EuiSpacer size="m" />
          </>

          <>
            {isDev && (
              <>
                <JobTags tags={jobTags} setTags={setTags} />
                <EuiSpacer size="m" />
              </>
            )}
          </>

          {!!fastTrackBookingEnabled && (
            <>
              <ParentJob existingJobId={parentJobId} onChangeExistingJob={setParentJobId} />
            </>
          )}

          {jobClassification.customerType === CustomerType.Domestic && (
            <>
              <CrossTradeReferrer referrer={referrer} onChange={setReferrer} />
              <EuiSpacer size="l" />
            </>
          )}

          <EuiFlexGroup>
            <EuiFlexItem grow={true}>
              <EuiFormRow label="Work required (to be shared with customer)" fullWidth>
                <EuiTextArea
                  value={workRequired}
                  onChange={(e) => setWorkRequired(e.target.value)}
                  rows={10}
                  fullWidth
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={true}>
              <EuiFormRow label="Internal notes for technician and DSR" fullWidth>
                <EuiTextArea
                  style={{ backgroundColor: '#FEFFE3' }}
                  value={internalNotes}
                  onChange={(e) => setInternalNotes(e.target.value)}
                  rows={10}
                  fullWidth
                  {...textAreaHighlighter}
                />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer />

          <EuiSpacer />
          <EuiButton
            color="primary"
            disabled={!isValid}
            fill
            onClick={() => bookJob(bookJobProps)}
            isLoading={waitingForApiResponse}
          >
            Create job
          </EuiButton>

          {createRequestError && (
            <>
              <EuiSpacer />
              <EuiCallOut color="danger" title={`Error occurred when creating job.`}>
                <p>{createRequestError.message}</p>
                <p>Details: {JSON.stringify(createRequestError)}</p>
                <p>Stack: {createRequestError.stack}</p>
              </EuiCallOut>
            </>
          )}
        </>
      )}
    </WorkflowAction>
  )
}
