import {
  EuiAccordion,
  EuiCallOut,
  EuiFlexGroup,
  EuiListGroup,
  EuiListGroupItem,
  EuiLoadingSpinner,
  EuiMarkdownFormat,
  EuiSpacer,
  EuiText
} from '@elastic/eui'
import { makeHumanReadable } from '@falloncore/util'
import {
  BookingScope,
  BookingWorkflowOutcomeFragment,
  PriorityType,
  TagFragment,
  WorkflowRuleFragment,
  WorkflowStepType
} from '@fallonsolutions/types'
import '@xyflow/react/dist/style.css'
import { compact } from 'lodash-es'
import { ReactElement } from 'react'
import priority1 from '../../static/images/emoji/priority1.png'
import priority2 from '../../static/images/emoji/priority2.png'
import priority3 from '../../static/images/emoji/priority3.png'
import { Tag } from '../../tags/tag'

export interface BookingConfigFlowChartSidebarProps {
  inputFields: { name: string; value: string | ReactElement }[]
  outcome?: BookingWorkflowOutcomeFragment
  preview?: WorkflowRuleFragment[]
  error?: Error
  loading?: boolean
}

// eslint-disable-next-line complexity
export const BookingConfigFlowChartSidebar = ({
  inputFields,
  preview,
  outcome,
  error,
  loading
}: BookingConfigFlowChartSidebarProps) => {
  const outcomeFields = outcome
    ? [
        { name: 'Trade', value: outcome?.trade?.name ?? '' },
        { name: 'Topic', value: outcome?.topic?.name ?? '' },
        { name: 'Subtopic', value: outcome?.subtopic?.name ?? '' },
        { name: 'Service type', value: outcome?.serviceType?.name ?? '' },
        {
          name: 'Steps / Questions',
          value: (
            <>
              {outcome?.steps
                ?.filter((step) =>
                  [WorkflowStepType.SingleChoice, WorkflowStepType.MultipleChoice, WorkflowStepType.Text].includes(
                    step.step.type
                  )
                )
                .map((step) => (
                  <div key={step.step.id}>
                    {step.step.reference}:{step.choice?.value ?? step.value ?? ''}
                  </div>
                ))}
            </>
          )
        },
        { name: 'Branch', value: outcome?.branch?.name ?? '' },
        { name: 'Priority', value: renderPriority(outcome.priority) },
        {
          name: 'Scope',
          value: renderScope(outcome.scope)
        },
        { name: 'Travel fee', value: outcome.travelFee?.name ?? '' },
        { name: 'Tags', value: renderTags(outcome.tags) }
      ]
    : []

  const previewFields = preview
    ? [
        { name: 'Trade', value: outcome?.trade?.name ?? '' },
        { name: 'Topic', value: outcome?.topic?.name ?? '' },
        { name: 'Subtopic', value: outcome?.subtopic?.name ?? '' },
        { name: 'Service type', value: outcome?.serviceType?.name ?? '' }
      ]
    : []

  const actionSteps = preview?.filter((r) => !!r.action.steps) ?? []

  return (
    <div
      style={{
        padding: '24px',
        width: '400px',
        height: '90%',
        position: 'absolute',
        background: 'rgba(255, 255, 255, 0.7)',
        backdropFilter: 'blur(4px)',
        right: 0,
        top: 0,
        overflowY: 'scroll'
      }}
    >
      <EuiAccordion
        id="accordion"
        buttonContent={
          <EuiText>
            <strong>Input</strong>
          </EuiText>
        }
        paddingSize="none"
      >
        <EuiSpacer size="s" />
        <table style={{ width: '100%' }}>
          <tbody>
            {inputFields.map((field) => (
              <tr key={field.name} style={{ borderBottom: '1px solid #f1f1f1' }}>
                <th style={{ padding: '6px 6px 7px', width: '100px' }}>{field.name}</th>
                <td style={{ padding: '6px 6px 7px' }}>{field.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </EuiAccordion>
      <EuiSpacer />

      <EuiAccordion
        id="accordion"
        initialIsOpen={true}
        buttonContent={
          <EuiText>
            <strong>Outcome</strong>
          </EuiText>
        }
        paddingSize="none"
      >
        {loading ? (
          <EuiLoadingSpinner />
        ) : error ? (
          <EuiCallOut color="danger">{error.message}</EuiCallOut>
        ) : outcomeFields ? (
          <>
            <EuiSpacer size="s" />
            <table style={{ width: '100%' }}>
              <tbody>
                {outcomeFields.map((field) => (
                  <tr key={field.name} style={{ borderBottom: '1px solid #f1f1f1' }}>
                    <th style={{ padding: '6px 6px 7px', width: '100px' }}>{field.name}</th>
                    <td style={{ padding: '6px 6px 7px', verticalAlign: 'middle' }}>{field.value}</td>
                  </tr>
                ))}
                {outcome?.travelFee && (
                  <tr style={{ borderBottom: '1px solid #f1f1f1' }}>
                    <td colSpan={2} style={{ padding: '6px 6px 7px' }}>
                      <EuiText size="s">Job close</EuiText>
                      <EuiSpacer size="s" />
                      <EuiMarkdownFormat textSize="xs">
                        {compact([
                          outcome.travelFee?.descriptionFormatted,
                          outcome.travelFee?.disclaimerFormatted
                        ]).join('\n\n')}
                      </EuiMarkdownFormat>
                    </td>
                  </tr>
                )}
                {outcome?.notes && (
                  <tr>
                    <td colSpan={2} style={{ padding: '6px 6px 7px' }}>
                      <EuiText size="s">Notes</EuiText>
                      <EuiSpacer size="s" />
                      <EuiMarkdownFormat textSize="xs">{outcome.notes}</EuiMarkdownFormat>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </>
        ) : (
          <></>
        )}
      </EuiAccordion>
      <EuiAccordion
        id="accordion"
        initialIsOpen={false}
        buttonContent={
          <EuiText>
            <strong>Preview</strong>
          </EuiText>
        }
        paddingSize="none"
      >
        {loading ? (
          <EuiLoadingSpinner />
        ) : error ? (
          <EuiCallOut color="danger">{error.message}</EuiCallOut>
        ) : previewFields ? (
          <>
            <EuiSpacer size="s" />
            <table style={{ width: '100%' }}>
              <tbody>
                {previewFields.map((field) => (
                  <tr key={field.name} style={{ borderBottom: '1px solid #f1f1f1' }}>
                    <th style={{ padding: '6px 6px 7px', width: '100px' }}>{field.name}</th>
                    <td style={{ padding: '6px 6px 7px', verticalAlign: 'middle' }}>{field.value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {preview?.length && preview.length > 0 ? (
              <tr>
                <td colSpan={2} style={{ padding: '6px 6px 7px' }}>
                  <EuiText size="s">Match ({preview.length})</EuiText>
                  <EuiSpacer size="s" />
                  <EuiListGroup>
                    {preview.map((rule) => (
                      <EuiListGroupItem key={rule.id} label={rule.name} />
                    ))}
                  </EuiListGroup>
                </td>
              </tr>
            ) : null}
            {actionSteps?.length && actionSteps.length > 0 ? (
              <tr>
                <td colSpan={2} style={{ padding: '6px 6px 7px' }}>
                  <EuiText size="s">All action step</EuiText>
                  <EuiSpacer size="s" />
                  <EuiListGroup>
                    {actionSteps.map((rule) =>
                      rule.action.steps?.map((step) => <EuiListGroupItem key={step.id} label={step.name} />)
                    )}
                  </EuiListGroup>
                </td>
              </tr>
            ) : null}
          </>
        ) : (
          <></>
        )}
      </EuiAccordion>
    </div>
  )
}

const renderScope = (scope: BookingScope) => {
  switch (scope) {
    case BookingScope.OutOfScope:
      return <span style={{ color: '#F03B00' }}>{makeHumanReadable(scope)}</span>
    case BookingScope.InScope:
      return <span style={{ color: '#42CB64' }}>{makeHumanReadable(scope)}</span>
    default:
      return makeHumanReadable(scope)
  }
}

const renderPriority = (priority: PriorityType) => {
  return (
    <div style={{ width: '24px', margin: '-3px' }}>
      <img
        src={imageForPriority(priority)}
        style={{ width: '24px', height: '24px', display: 'block', margin: 'auto' }}
        alt={priority}
      />
    </div>
  )
}

const renderTags = (tags: TagFragment[] | undefined | null) => (
  <EuiFlexGroup gutterSize="s">
    {tags?.map((tag) => {
      return <Tag key={tag.id} tag={tag} />
    })}
  </EuiFlexGroup>
)

const imageForPriority = (priority: PriorityType) => {
  switch (priority) {
    case PriorityType.P1:
      return priority1
    case PriorityType.P2:
      return priority2
    case PriorityType.P3:
      return priority3
    default:
      return priority
  }
}
