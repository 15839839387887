import createPersistedState from '../use-persisted-state'

export const useFastBooking = createPersistedState<boolean>('fastBookingScript')
export const useWorkflowV2 = createPersistedState<boolean>('workflow-v2')
export const useAbbreviatedMetrics = createPersistedState<boolean>('metrics-abbreviated')
export const useNavigationIcons = createPersistedState<boolean>('navigation-icons')
export const useAppConfigCacheExpiry = createPersistedState<string>('appConfigExpiry')
export const useAppConfigCache = createPersistedState<any>('appConfig')
export const useConfigUrl = createPersistedState<string | undefined>('appConfigUrl')
export const useContactDuplicateCheck = createPersistedState<boolean>('contactDuplicateCheck')
export const useJobListMetricsNewDefinitions = createPersistedState<boolean>('job-list-metrics-new-definitions')
export const useScheduleUIEarlyAccess = createPersistedState<boolean>('schedule-ui-early-access')
export const useEarlyAccess = createPersistedState<boolean>('early-access')
