import { BookingWindow } from '@fallonsolutions/appointment'
import { TimeAllowed } from '@fallonsolutions/job'
import {
  AfterHours,
  AttributeRequirementInput,
  CommunicationChannelSelection,
  CustomerType,
  Flexibility,
  JobCategoryId,
  JobStatusType,
  JobTopicType,
  JobType,
  MembershipLevel,
  PriorityRequirementInput,
  ScheduleEventFragment,
  TradeType
} from '@fallonsolutions/types'
import { Moment } from 'moment-timezone'
import { BrandOptions } from '../../brand/brand-selector'

export interface BookJobProps {
  tagIds: string[] | undefined
  customerId: string | undefined
  propertyId: string | undefined
  brand: BrandOptions
  parentJobId?: string | null | undefined
  topic?: JobTopicType | null | undefined
  notes: string | null | undefined
  workRequired: string | null | undefined
  trade: TradeType | null | undefined
  type: JobType | null | undefined
  customerType: CustomerType | null | undefined
  purchaseOrderNumber: string
  category: string | undefined
  membershipLevel: MembershipLevel
  acquisitionChannel: string | null | undefined
  startDate: Moment | null | undefined
  bookingWindow: BookingWindow
  timeAllowed: TimeAllowed
  delayedJobStatus: JobStatusType | undefined
  reserveNow: boolean
  reservedScheduleEvent: ScheduleEventFragment | undefined
  attributeRequirements: AttributeRequirementInput[] | undefined
  flexibility: Flexibility | undefined
  priorityRequirement: PriorityRequirementInput | null | undefined
  membershipJob: JobCategoryId | undefined
  referrerId?: string
  afterHours?: AfterHours | undefined
  bookingConfirmation: CommunicationChannelSelection | undefined
  contactId: string | undefined
}

export const arePrerequisitesValid = (props: BookJobProps): boolean => {
  const reservedScheduleEvent = !!props.reservedScheduleEvent
  const reserveLaterValid = !props.reserveNow && props.delayedJobStatus
  const reservationSelectionValid = reservedScheduleEvent || reserveLaterValid
  const reservationRequiredAndValid = !props.membershipJob && reservationSelectionValid
  const isValid = !!(
    !!props.customerId &&
    !!props.propertyId &&
    !!props.customerType &&
    !!props.trade &&
    !!props.type &&
    !!props.category &&
    !!props.topic &&
    (reservationRequiredAndValid || !!props.membershipJob) &&
    props.workRequired?.length &&
    props.notes?.length
  )
  return isValid
}
