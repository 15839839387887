// type caching policies
// needed to assist cache with normalization of values when type values dont meet default uniqueness of __typename + id
// see: https://www.apollographql.com/docs/react/caching/cache-configuration/#typepolicy-fields
// see also: https://github.com/apollographql/apollo-client/issues/6868
// see also https://spectrum.chat/apollo/apollo-client/is-there-a-way-to-return-the-parent-element-in-the-typepolicies-fields~9d06057c-6bcd-4ea0-b93c-e48dc062daa4

//note false disabled normalization, and false as const restricts the type to false as opposed to boolean

import { StoreObject } from '@apollo/client'
import { TypePolicies } from '@apollo/client/cache/inmemory/policies'

export const typePolicies: TypePolicies = {
  User: {
    fields: {
      contact: {
        merge: true
      },
      contactDetail: {
        merge: true
      }
    }
  },
  Customer: {
    fields: {
      company: {
        merge: true
      },
      contact: {
        merge: true
      }
    }
  },
  Job: {
    fields: {
      optionSheet: {
        merge: true
      },
      media: {
        merge: false
      }
    }
  },
  PerformanceScheme: {
    keyFields: false as const,
    merge: false,
    fields: {
      rules: {
        merge: false
      }
    }
  },
  InspectionCheck: {
    keyFields: false as const
  },
  ItemTemplateSection: {
    keyFields: false as const
  },
  ConfigurationItem: {
    keyFields: false as const
  },
  ConfigurationAdjustment: {
    keyFields: false as const
  },
  OptionAdjustment: {
    keyFields: false as const
  },
  LegacyJobData: {
    keyFields: false as const
  },
  Item: {
    keyFields: (object: Readonly<StoreObject>) => [...(object.id ? ['id'] : []), ...(object.version ? ['version'] : [])]
  },
  // TODO: ensure all Part objects (including those in item.parts) have id and version
  // Part: {
  //   keyFields: ['id', 'version']
  // },
  // TODO: ensure all LabourRate objects (including those in item.labourRates) have id and version
  // LabourRate: {
  //   keyFields: ['id', 'version']
  // },
  Appointment: {
    fields: {
      technicians: {
        merge: false
      }
    }
  },
  ScheduleEventCustomerContact: {
    keyFields: false as const
  },
  ScheduleEventCustomer: {
    keyFields: false as const
  },
  ScheduleEvent: {
    fields: {
      status: {
        merge: true
      },
      availableOfficeStatuses: {
        merge: false
      }
    },
    keyFields: ['id']
  },
  ScheduleEventStatus: {
    keyFields: false as const
  },
  Schedule: {
    keyFields: false as const
  },
  ScheduleLaneDay: {
    fields: {
      slots: {
        merge: false
      }
    }
  },
  AvailableScheduleEventStatus: {
    keyFields: false as const
  },
  UserAttribute: {
    keyFields: false as const
  },
  TimesheetEntry: {
    keyFields: (object: Readonly<StoreObject>) => [...(object.id ? ['id'] : []), ...(object.version ? ['version'] : [])]
  },
  Contact: {
    keyFields: (object: Readonly<StoreObject>) => [...(object.id ? ['id'] : []), ...(object.version ? ['version'] : [])]
  },
  ContactDetail: {
    keyFields: false as const
  },
  RiskAssessmentRisk: {
    keyFields: false as const
  },
  Workflow: {
    fields: {
      steps: {
        merge: false
      }
    }
  },
  WorkflowStepResult: {
    merge: (_existing, incoming) => {
      return incoming
    }
  }
}
