import {
  CustomerTypeSubmitWorkflowStepDetailInput,
  CustomerTypeWorkflowStepDetailFragment
} from '@fallonsolutions/types'
import { AnswerOption, SingleSelectField } from '../../workflow/question-fields/single-select-question-field'
import { WorkflowV2StepProps } from './workflow-v2-step-interface'

export interface CustomerTypeWorkflowV2StepProps extends WorkflowV2StepProps {
  detail: CustomerTypeWorkflowStepDetailFragment
  result: Partial<CustomerTypeSubmitWorkflowStepDetailInput>
  onChange: (result: CustomerTypeSubmitWorkflowStepDetailInput) => void
}

export const CustomerTypeWorkflowV2Step = ({ result, onChange, detail }: CustomerTypeWorkflowV2StepProps) => {
  const choiceId = result.choiceId

  const options: AnswerOption<string>[] = detail.choices.map((choice) => ({
    id: choice.id,
    label: choice.label
  }))

  return (
    <>
      <SingleSelectField
        question={detail.question}
        options={options}
        answer={choiceId}
        changeAnswer={(answer) => answer && onChange({ choiceId: answer })}
      />
    </>
  )
}
