import { EuiContextMenu, EuiContextMenuPanelDescriptor, EuiPopover } from '@elastic/eui'
import { ReactElement } from 'react'

export interface TagContextMenuProps {
  children: ReactElement
  onClose: () => void
  removeTag: () => void
  loading: boolean
  visible: boolean
}

export const TagContextMenu = (props: TagContextMenuProps) => {
  const { children, visible, onClose, removeTag } = props

  const contextMenuPanels: EuiContextMenuPanelDescriptor[] = [
    {
      id: 0,
      items: [
        {
          icon: 'editorUndo',
          name: 'Remove',
          onClick: () => removeTag()
        }
      ]
    }
  ]

  return (
    <>
      <EuiPopover
        id="export-popover"
        button={children}
        isOpen={visible}
        closePopover={onClose}
        panelPaddingSize="none"
        anchorPosition="downLeft"
        ownFocus={false}
      >
        <EuiContextMenu panels={contextMenuPanels} initialPanelId={0} />
      </EuiPopover>
    </>
  )
}
