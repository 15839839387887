import { useMutation, useQuery } from '@apollo/client'
import { EuiButton, EuiLoadingSpinner } from '@elastic/eui'
import { WorkflowFragment, WorkflowState } from '@fallonsolutions/types'
import { useEffect, useState } from 'react'
import { CreateWorkflowDocument, CustomerType, SearchTopicsDocument, Topic } from '../../api/generated-types'
import { Callout } from '../../common/callout'
import WorkflowV2Container from '../../workflow-v2/workflow-v2-container'
import { enquiryBridge } from '../helpers/workflow-v2-bridge'
import { WorkflowAction } from '../workflow-action'
import { WorkflowActionProps } from '../workflow-model'
import { ContactRole } from './action-customer-qualification'
import { JobClassificationActionInput, JobClassificationActionResult } from './action-job-classification-types'
import { first } from 'lodash-es'
import { useAuthenticated } from '../../auth/authenticated-context'
import { makeHumanReadable } from '../../common/utils'
import { JobAssignment } from '@fallonsolutions/job'

const customerServiceBookingWorkflowTemplateId = '67753a04-7ffc-429e-9a59-181d2dc2e7a4'

export interface JobClassificationActionV2Result extends JobClassificationActionResult {
  assignment: JobAssignment
}

export const JobClassificationActionV2 = (
  props: WorkflowActionProps<JobClassificationActionInput, JobClassificationActionResult>
) => {
  const { input, result, onUpdate } = props
  const { editable } = input
  const developerMode = useAuthenticated().developerFeatures

  const [createWorkflow, { loading, data, error }] = useMutation(CreateWorkflowDocument)

  const workflow = data?.createWorkflow.workflow

  const { data: searchTopicsData, loading: searchTopicsLoading } = useQuery(SearchTopicsDocument, {
    variables: { input: { size: 100 } },
    skip: !!props.input.jobTopic.id || !props.input.jobTopic.reference
  })

  const [resolvedTopic, setTopic] = useState<Topic | undefined>(undefined)
  // lookup topicId by topicType
  useEffect(() => {
    const topicType = props.input.jobTopic.reference
    if ((searchTopicsData?.searchTopics?.count ?? 0 > 0) || resolvedTopic?.type !== topicType) {
      console.log('resolve ref', topicType)
      const loadedResolvedTopic = first(
        searchTopicsData?.searchTopics.results.filter((topic) => topic.type === topicType)
      )
      // if (!resolvedTopic) {
      //   searchTopicsData?.searchTopics.results.map((t) => {
      //     console.log(t.type, topicType)
      //   })
      // }
      console.log('resolved', loadedResolvedTopic?.id, loadedResolvedTopic?.reference, loadedResolvedTopic?.name)
      setTopic(loadedResolvedTopic as Topic)
    }
  }, [searchTopicsData, searchTopicsLoading, resolvedTopic])

  useEffect(() => {
    const topicType = input.jobTopic.reference
    const contactRole = input.customerContactRole ?? ContactRole.HomeOwner
    const customerType = contactRole === ContactRole.HomeOwner ? CustomerType.Domestic : CustomerType.Commercial
    if (!data?.createWorkflow.workflow && !loading && resolvedTopic) {
      console.log('createWorkflow', { topicType, customerType, ...resolvedTopic })
      createWorkflow({
        variables: {
          input: {
            workflowTemplateId: customerServiceBookingWorkflowTemplateId,
            initialState: {
              booking: {
                ...(resolvedTopic && { topicId: resolvedTopic.id, tradeId: resolvedTopic.tradeId }),
                customerType
              }
            }
          }
        }
      })
    } else {
      console.log('skip create workflow', loading, workflow, resolvedTopic?.id)
    }
  }, [resolvedTopic, data, loading])

  const onChange = () => {
    onUpdate({ ...result, actionCompleted: false, value: undefined })
  }

  const handleSubmit = (workflow?: WorkflowFragment) => {
    console.log('bridge to enquiry', workflow)
    const updatedResult = enquiryBridge(workflow)
    console.log('bridged update', updatedResult)
    onUpdate({ ...result, ...updatedResult })
  }

  const complete = () => {
    const { customerType, trade, type, category } = result ?? {}
    const value = `${customerType} | ${trade} | ${makeHumanReadable(category)} | ${type}`
    onUpdate({ ...result, actionCompleted: true, value })
  }

  return loading || !resolvedTopic ? (
    <EuiLoadingSpinner />
  ) : workflow ? (
    <WorkflowAction title="Job classification" value={result?.value} onClickChange={onChange} editable={editable}>
      <WorkflowV2Container workflowId={workflow.id} onSubmit={handleSubmit} />
      {developerMode && <EuiButton onClick={complete}>Complete</EuiButton>}
    </WorkflowAction>
  ) : (
    <Callout title="Error creating workflow" type="failure">
      {error?.message}
    </Callout>
  )
}
