import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useApp } from '../app/app-context'
import { CognitoInteractor } from './cognito-interactor'
import { SessionState } from './session'
import { ISessionContext, NewSessionState, SessionContext, SessionLoaderContext } from './session-context'
//wip: upgrade dependency for cognito authentication
// import { CognitoInteractor } from './cognito-interactor-updated-dependency'
import { EuiImage } from '@elastic/eui'
import { useSessionStorage } from '../common/use-session-storage'

interface AuthenticationProps {
  children: React.ReactNode
}

const AuthenticationProvider = (props: AuthenticationProps) => {
  const appContext = useApp()
  const history = useHistory()
  const [mediaAuthCount, setAuthCount] = useSessionStorage('media_auth', 0)
  const [thirdPartyCookieResponse, setThirdPartyCookieResponse] = useState(false)

  const mediaAuthCheckSrc = `https://${appContext.mediaDomain}/jobs/access-check/v1?e=png`

  const authnInteractor = new CognitoInteractor(appContext)

  const [loadingState, setLoadingState] = useState({
    loading: false,
    signedIn: false
  })
  const [sessionState, setSessionState] = useState<NewSessionState>({})

  const signInSession = (user: SessionState) => {
    setLoadingState({ signedIn: true, loading: false })
    setSessionState((sessionState) => ({ ...sessionState, user }))
  }

  const clearSession = () => {
    console.log('clear session')
    setLoadingState({ signedIn: false, loading: false })
    setAuthCount(0)
    setSessionState((sessionState) => ({
      ...sessionState,
      user: undefined,
      userFragment: undefined
    }))
  }

  const sessionData: ISessionContext = {
    ...sessionState,
    signIn: () => {
      console.log('authentication-provider: signing in')
      setSessionState((sessionState) => ({ ...sessionState, loading: true }))
      window.location.href = authnInteractor.signInUri()
    },
    signOut: () => {
      console.log('authentication-provider: signing out')
      authnInteractor.signOut()
      clearSession()
    },
    handleCallback: async (url: string, redirectUrl?: string) => {
      console.log('authentication-provider: handling auth callback')
      try {
        console.log('authentication-provider: handling auth callback', url, redirectUrl)
        const user = await authnInteractor.handleCallback(url, redirectUrl)
        if (user) {
          signInSession(user)
        } else {
          throw new Error('no user session')
        }
        if (redirectUrl) {
          history.replace(redirectUrl)
        }
      } catch (err) {
        console.log('authentication-provider: error handling auth callback', err)
        clearSession()
      }
    },
    refresh: async () => {
      console.log('authentication-provider: refreshing auth')
      setSessionState((sessionState) => ({ ...sessionState, loading: true }))
      setAuthCount(0)
      setThirdPartyCookieResponse(false)
      try {
        const userSession = await authnInteractor.refresh()
        if (userSession) {
          signInSession(userSession)
          history.replace('/') // use redirectUrl?
          return userSession
        } else {
          // TODO: what should happen here?
          console.log('authentication-provider: no user session')
          setSessionState((sessionState) => ({ ...sessionState, loading: false }))
          return undefined
        }
      } catch (err) {
        console.log('authentication-provider: error refreshing auth', err)
        clearSession()
        return undefined
      }
    }
  }

  const session: SessionLoaderContext = {
    loading: loadingState.loading,
    loaded: loadingState.signedIn,
    error: '',
    data: sessionData,
    retry: sessionData.refresh
  }

  const getCognitoSession = useCallback(async () => {
    const user = await authnInteractor.getSessionState()
    if (user?.signedIn === true) {
      signInSession(user)
    } else {
      clearSession()
    }
  }, [])

  useEffect(() => {
    getCognitoSession()
  }, [])

  const mediaAuthCorsEnabled = true //appContext.isDev || appContext.isTest
  const mediaAuthFirstPartyEnabled = false // appContext.isDev

  useEffect(() => {
    const fetchMediaAuthFetch = async () => {
      console.log('authentication-provider: media authorization 3rd party call')
      if (!mediaAuthCorsEnabled || thirdPartyCookieResponse === true) {
        if (mediaAuthCount === 3) {
          setAuthCount(0)
        }
        return
      }
      const response = await fetch(`https://${appContext.mediaDomain}/token`, {
        mode: 'cors',
        method: 'GET',
        cache: 'no-cache',
        credentials: 'include', //browser set cookie cross origin https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API#differences_from_jquery
        ...(session.data?.user?.token && { headers: [['media_token', session.data?.user?.token]] })
      })
      setThirdPartyCookieResponse(true)
      console.log('authentication-provider: media authorization 3rd party response', response.status)
    }
    !!session.data?.user?.token &&
      session.data?.user?.token !== 'MISSING ID TOKEN' &&
      !thirdPartyCookieResponse &&
      fetchMediaAuthFetch()
  }, [session.data?.user?.token, thirdPartyCookieResponse])

  const firstPartyMediaAuthSuccess = () => {
    console.log('authentication-provider: media access 1st party ok')
    setAuthCount(0)
  }

  const firstPartyMediaAuthError = (_event: any) => {
    console.error('authentication-provider: media access 1st party failed')
    console.log(
      `authentication-provider: 1st party redirect. ${mediaAuthCount}`,
      `https://${appContext.mediaDomain}/auth-callback?media_token=${session.data?.user?.token}`
    )
    if (mediaAuthCount < 3) {
      setAuthCount(mediaAuthCount + 1)
      window.location.href = `https://${appContext.mediaDomain}/auth-callback?media_token=${session.data?.user?.token}` //can we avoid passing the idToken in url, could dish out another token, that had user payload?
    } else {
      if (mediaAuthCount >= 3) {
        console.log('consider reset session storage')
      }
      console.error(`authentication-provider: media auth failed ${mediaAuthCount}`)
    }
    //todo: avoid passing idToken in url, if we can't reuse the code, may have to encrypt idToken, or consider using a diff token enc kp that contains user payload and auth via OAI IAM instead of actual user token
  }

  return (
    <SessionContext.Provider value={session}>
      <>
        {props.children}
        {loadingState.signedIn && mediaAuthFirstPartyEnabled && thirdPartyCookieResponse && (
          <>
            <EuiImage
              src={mediaAuthCheckSrc}
              alt="media access check"
              hidden={true}
              onError={firstPartyMediaAuthError}
              onLoad={firstPartyMediaAuthSuccess}
            />
          </>
        )}
      </>
    </SessionContext.Provider>
  )
}

export default AuthenticationProvider
