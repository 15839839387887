import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import { getTagColorValue } from '@fallonsolutions/tag'
import { TagFragment } from '@fallonsolutions/types'
import '../static/css/tag.css'

export interface TagProps {
  tag: TagFragment
  size?: TagSize
}

export enum TagSize {
  Default = 'default', // emoji with label
  Compact = 'compact', // emoji with reference
  Tiny = 'tiny' // emoji only
}

export const Tag = ({ tag, ...props }: TagProps) => {
  const { light, lightBorder } = getTagColorValue(tag.color)
  const size = props.size ?? TagSize.Default

  return (
    <div
      className={`tag tag--${size}`}
      style={{ backgroundColor: light, ...(lightBorder ? { border: `1px solid ${lightBorder}` } : {}) }}
    >
      <EuiFlexGroup gutterSize="s" alignItems="center" title={tag.description ?? tag.label}>
        <EuiFlexItem grow={true} style={{ fontSize: '16px' }}>
          {tag.emoji}
        </EuiFlexItem>
        {size === TagSize.Default && <EuiFlexItem grow={false}>{tag.label}</EuiFlexItem>}
        {size === TagSize.Compact && <EuiFlexItem grow={false}>{tag.reference.toUpperCase()}</EuiFlexItem>}
      </EuiFlexGroup>
    </div>
  )
}
