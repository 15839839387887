import { useMutation } from '@apollo/client'
import {
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiModal,
  EuiRadio,
  EuiRadioGroup,
  EuiRadioGroupOption,
  EuiSpacer,
  EuiText,
  EuiTitle
} from '@elastic/eui'
import { AppliedTagFragment, ContactCustomerRole, CustomerFragment, TagTarget } from '@fallonsolutions/types'
import { useState } from 'react'
import {
  Address,
  CustomerLocationFragment,
  CustomerLocationStatus,
  CustomerLocationStatusType,
  MembershipStatusType,
  UpdateCustomerLocationStatusDocument
} from '../api/generated-types'
import { useAuthenticated } from '../auth/authenticated-context'
import { Callout } from '../common/callout'
import { useCreateInitialEnquiryService } from '../common/create-initial-enquiry'
import { createNewJobUrl } from '../common/create-new-job-url'
import { openUrlInNewTab } from '../common/open-in-new-tab'
import { CustomerLocationStatusBadge } from '../customer-locations/customer-location-status-badge'
import { PropertyAdd } from '../properties/property-add'
import { TagList } from '../tags/tag-list'
import { canUseFastBooking } from '../workflow/workflow-new-enquiry-wrapper'

export interface CustomerLocationsContainerProps {
  customerId: string
  refresh?: () => void
  onSelect?: (location: CustomerLocationFragment) => void
  showSelected?: boolean
  highlightedLocation?: string
  filter?: (location: CustomerLocationFragment) => boolean
  compactView?: boolean
  showActions?: boolean
  customer?: CustomerFragment | null
}
export const CustomerLocationContainer = (props: CustomerLocationsContainerProps) => {
  const { customerId, onSelect, showSelected, highlightedLocation, filter, compactView, customer, showActions } = props
  const compact = compactView !== undefined ? compactView : false
  const user = useAuthenticated().userFragment
  const [isAddModalVisible, setIsAddModalVisible] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState<CustomerLocationFragment | undefined>()
  const [createInitialEnquiry] = useCreateInitialEnquiryService()

  const [updateLocationStatus] = useMutation(UpdateCustomerLocationStatusDocument, {
    refetchQueries: ['GetCustomerLocation', 'GetCustomer']
  })

  const locations: CustomerLocationFragment[] = (customer?.locations ?? []).filter(filter ?? (() => true))

  const [isChangeStatusModalVisible, setIsChangeStatusModalVisible] = useState(false)
  const addNewProperty = () => {
    setIsAddModalVisible(true)
    setSelectedLocation(undefined)
  }

  const changeLocationStatus = (locationId: string) => {
    setSelectedLocation(locations?.find((l) => l.id === locationId))
    setIsChangeStatusModalVisible(true)
  }

  const onStatusChange = (locationId: string, status: CustomerLocationStatusType) => {
    setIsChangeStatusModalVisible(false)
    updateLocationStatus({
      variables: {
        input: {
          locationId,
          status
        }
      }
    })
  }

  const handleCreateNewJobForLocation = async (location: CustomerLocationFragment) => {
    if (!location || !customer) return
    const enquiryId = await createInitialEnquiry({ customer })
    const contactId = customer.contacts?.find((c) =>
      c.roles.some((role) => role === ContactCustomerRole.Primary)
    )?.contactId
    const url = createNewJobUrl({
      enquiryId,
      customerId: customer.id,
      contactId,
      customerLocationId: location.id
    })
    openUrlInNewTab(url)
  }

  return (
    <>
      {!compact && (
        <>
          <EuiFlexGroup alignItems="center">
            <EuiFlexItem grow={false}>
              <EuiTitle size="s">
                <h2>Properties</h2>
              </EuiTitle>
            </EuiFlexItem>
            {/* <EuiFlexItem grow={false}>
                  <EuiButtonEmpty iconType="refresh" onClick={handleRefresh} size="xs">
                    Reload customer properties
                  </EuiButtonEmpty>
                </EuiFlexItem> */}
            <EuiFlexItem grow={true} />
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty onClick={addNewProperty}>Add new property</EuiButtonEmpty>
            </EuiFlexItem>
          </EuiFlexGroup>
        </>
      )}

      {isAddModalVisible && <PropertyAdd customerId={customerId} closeModal={() => setIsAddModalVisible(false)} />}
      {isChangeStatusModalVisible && selectedLocation && (
        <CustomerLocationStatusChangeModal
          location={selectedLocation}
          closeModal={() => setIsChangeStatusModalVisible(false)}
          updateLocationStatus={onStatusChange}
        />
      )}
      {locations && locations.length ? (
        <>
          <CustomerLocationTable
            locations={locations}
            loading={false}
            changeLocationStatus={changeLocationStatus}
            onSelect={onSelect}
            showSelected={showSelected}
            highlightedLocation={highlightedLocation}
            allowCreateNewJob={canUseFastBooking(user)}
            createNewJob={handleCreateNewJobForLocation}
            canCreateNewJob={!!customer}
            showActions={showActions}
          />
        </>
      ) : (
        <div>No properties found for this customer</div>
      )}
    </>
  )
}

interface CustomerLocationTableProps {
  locations: CustomerLocationFragment[]
  loading: boolean
  changeLocationStatus: (locationId: string) => void
  onSelect?: (location: CustomerLocationFragment) => void
  showSelected?: boolean
  showActions?: boolean
  highlightedLocation?: string
  allowCreateNewJob?: boolean // whether button appears
  canCreateNewJob?: boolean // where button is interactable
  createNewJob?: (location: CustomerLocationFragment) => Promise<void>
}

const CustomerLocationTable = (props: CustomerLocationTableProps) => {
  const {
    locations,
    loading,
    changeLocationStatus,
    onSelect,
    showSelected,
    highlightedLocation,
    showActions,
    allowCreateNewJob,
    createNewJob,

    canCreateNewJob
  } = props
  const displayActions = showActions === undefined ? true : !!showActions
  const columns: EuiBasicTableColumn<CustomerLocationFragment>[] = [
    ...(showSelected
      ? [
          {
            field: 'id',
            name: '',
            width: '40px',
            render: (id: string, location: CustomerLocationFragment) => (
              <EuiRadio checked={id === highlightedLocation} onChange={() => onSelect?.(location)} />
            )
          }
        ]
      : []),
    {
      field: 'address',
      name: 'Street',
      sortable: true,
      render: (address: Address | undefined, location: CustomerLocationFragment) => {
        if (address) {
          return (
            <a href={`/properties/${location.property.id}`}>
              {[address.street, address.street2].filter((n) => !!n).join(', ')}
            </a>
          )
        }
        return '-'
      }
    },
    {
      field: 'address.suburb',
      name: 'Suburb',
      sortable: true
    },
    {
      field: 'address.postcode',
      name: 'Postcode',
      sortable: true
    },
    {
      field: 'address.state',
      name: 'State',
      sortable: true
    },
    {
      field: 'status',
      name: 'Status',
      render: (status: CustomerLocationStatus) => {
        return <CustomerLocationStatusBadge status={status.status} title={'status'} />
      }
    },
    {
      field: 'tags',
      name: 'Tags',
      render: (tags: AppliedTagFragment[] | undefined, customerLocation: CustomerLocationFragment) => {
        return (
          <>
            <TagList
              tags={tags ?? []}
              objectId={customerLocation.id}
              objectIdentifier={customerLocation.formattedAddress}
              kind={TagTarget.CustomerLocation}
            />
          </>
        )
      }
    },
    ...(displayActions
      ? [
          {
            name: 'Actions',
            actions: [
              {
                name: 'Change status',
                description: 'Change status',
                icon: 'inputOutput',
                type: 'icon',
                onClick: (location: CustomerLocationFragment) => changeLocationStatus(location.id)
              },
              ...(allowCreateNewJob
                ? [
                    {
                      name: 'Create new job',
                      description: 'Create new job for this customer & property',
                      icon: 'plusInCircleFilled',
                      type: 'icon',
                      available: () => canCreateNewJob,
                      onClick: async (location: CustomerLocationFragment) => createNewJob && createNewJob(location)
                    }
                  ]
                : [])
            ]
          } as EuiBasicTableColumn<CustomerLocationFragment>
        ]
      : [])
  ]

  return (
    <EuiBasicTable
      className="basic-table--minimal"
      loading={loading}
      items={locations}
      columns={columns}
      noItemsMessage={loading ? 'Searching...' : 'No properties found'}
      data-test-id="customer-properties-table"
      rowProps={(row) => ({ onClick: () => onSelect?.(row), 'data-test-id': 'property-search-result-row' })}
    />
  )
}

interface CustomerLocationStatusChangeModalProps {
  location: CustomerLocationFragment
  closeModal: () => void
  updateLocationStatus: (locationId: string, status: CustomerLocationStatusType) => void
}

const customerLocationStatuses = [
  CustomerLocationStatusType.Current,
  CustomerLocationStatusType.Error,
  CustomerLocationStatusType.Previous
]

const invalidMembershipStatuses = [MembershipStatusType.Active, MembershipStatusType.Pending]
export const CustomerLocationStatusChangeModal = ({
  closeModal,
  location,
  updateLocationStatus
}: CustomerLocationStatusChangeModalProps) => {
  const [selectedStatus, setSelectedStatus] = useState<CustomerLocationStatusType | undefined>(undefined)
  const radioGroups: EuiRadioGroupOption[] = customerLocationStatuses
    .filter((status) => status !== location.status.status)
    .map((status) => ({
      id: status,
      label: CustomerLocationStatusBadge({ status, title: '' })
    }))

  const locationContainsMembership = location.memberships?.some((m) =>
    invalidMembershipStatuses.includes(m.status.status)
  )
  const buttonDisabled = !selectedStatus || locationContainsMembership
  return (
    <EuiModal onClose={closeModal} style={{ padding: '10px' }}>
      <EuiTitle size="s">
        <h2>Change status</h2>
      </EuiTitle>
      <EuiSpacer />
      <Callout title="" type="note">
        <EuiText>
          <p> setting a property as "previous" or "error" will prevent it from being selectable in bouncing ball</p>
        </EuiText>
      </Callout>
      {locationContainsMembership && (
        <>
          <EuiSpacer size="xs" />
          <Callout title="" type="warning">
            <EuiText>
              <p>
                This property has an active or pending membership. Either cancel the membership or change the property
                it is attached to
              </p>
            </EuiText>
          </Callout>
        </>
      )}
      <EuiSpacer />
      <EuiRadioGroup
        options={radioGroups}
        idSelected={selectedStatus}
        onChange={(id) => setSelectedStatus(id as CustomerLocationStatusType)}
      />
      <EuiFlexGroup justifyContent="flexEnd">
        <EuiFlexItem grow={false}>
          <EuiButtonEmpty onClick={closeModal}>Cancel</EuiButtonEmpty>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButton
            onClick={() => updateLocationStatus(location.id, selectedStatus ?? CustomerLocationStatusType.None)}
            fill={true}
            disabled={buttonDisabled}
          >
            Update status
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiModal>
  )
}
