import { useMutation } from '@apollo/client'
import { EuiButton, EuiButtonEmpty, EuiFlexGroup, EuiFlexItem, EuiLink, EuiSpacer } from '@elastic/eui'
import { ContentState, Editor, EditorState } from 'draft-js'
import 'draft-js/dist/Draft.css'
import { useRef, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import {
  JobFragment,
  TagTarget,
  UpdateJobWorkRequiredDocument,
  UpdateJobWorkRequiredInput
} from '../api/generated-types'
import '../static/css/comment.css'
import { TagList } from '../tags/tag-list'

export interface JobWorkRequiredProps {
  job: JobFragment
  workRequired: string
  isEditable: boolean
}

export const JobWorkRequired = (props: JobWorkRequiredProps) => {
  const { job, workRequired, isEditable } = props

  const editorRef = useRef<Editor>(null)

  const [isEditorVisible, setIsEditorVisible] = useState(false)

  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createWithContent(ContentState.createFromText(workRequired))
  )

  const content = editorState.getCurrentContent()
  const hasContent = content.getPlainText().length > 0

  const [updateJobWorkRequired, { loading }] = useMutation(UpdateJobWorkRequiredDocument, {
    // refetchQueries: ['GetJob'],
    // awaitRefetchQueries: true,
    onCompleted: (data) => {
      const updatedContent = data.updateJobWorkRequired?.job?.workRequired ?? ''
      setEditorState(EditorState.createWithContent(ContentState.createFromText(updatedContent)))
      setIsEditorVisible(false)
    }
  })

  const submitClick = () => {
    if (isEditable) {
      const input: UpdateJobWorkRequiredInput = {
        job: job.id,
        workRequired: content.getPlainText()
      }
      updateJobWorkRequired({
        variables: { input }
      })
    }
  }

  const cancelEditing = () => setIsEditorVisible(false)
  const startEditing = () => {
    setIsEditorVisible(true)
    editorRef.current?.focus()
  }
  const resetEditor = () =>
    setEditorState(EditorState.createWithContent(ContentState.createFromText(workRequired ?? '')))

  const displayWorkRequired = workRequired
    ? workRequired.split('\n').join('\n\n')
    : isEditable
      ? 'Add work required...'
      : ''
  const hasChanges = workRequired !== content.getPlainText()

  return (
    <div style={{ maxWidth: '720px' }}>
      <EuiFlexGroup gutterSize="none" alignItems="baseline">
        <EuiFlexItem grow={true}>
          <div className="medium-label">WORK REQUIRED</div>
        </EuiFlexItem>
        {isEditable && isEditorVisible && hasChanges && (
          <EuiFlexItem grow={false}>
            <EuiLink color="subdued" onClick={resetEditor}>
              Reset
            </EuiLink>
          </EuiFlexItem>
        )}
        <EuiFlexItem grow={false}>
          <TagList objectId={job.id} tags={job.tags ?? []} kind={TagTarget.Job} objectIdentifier={job.number} />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="m" />
      {isEditable && (
        <div style={{ display: isEditorVisible ? 'block' : 'none' }}>
          <div className={`editor ${hasContent ? 'editor--has-content' : ''}`}>
            <Editor
              ref={editorRef}
              editorState={editorState}
              onChange={setEditorState}
              placeholder="Enter work required..."
            />
          </div>
          <EuiSpacer size="s" />
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiButton size="s" color="primary" isLoading={loading} onClick={submitClick} fill>
                {loading ? 'Saving...' : 'Save'}
              </EuiButton>
            </EuiFlexItem>
            {!loading && (
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty size="s" onClick={cancelEditing}>
                  Cancel
                </EuiButtonEmpty>
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        </div>
      )}
      {!isEditorVisible && (
        <>
          <div
            className={isEditable ? 'secondary-link' : ''}
            onClick={isEditable ? startEditing : undefined}
            data-test-id="job-work-required-text"
          >
            <ReactMarkdown children={displayWorkRequired} className="markdown-body" />
          </div>
          {isEditable && hasChanges && (
            <>
              <EuiSpacer size="s" />
              <span style={{ fontSize: '12px' }}>(Unsaved changes)</span>
            </>
          )}
        </>
      )}
    </div>
  )
}
