import {
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiLink,
  EuiSpacer,
  EuiTab,
  EuiTabbedContentTab,
  EuiTabs,
  EuiText
} from '@elastic/eui'
import { orderBy } from 'lodash-es'
import { useState } from 'react'
import {
  ContactInput,
  EnquiryType,
  InteractionMessageFragment,
  UserCallCenterParticipationType
} from '../../api/generated-types'
import { useAuthenticated } from '../../auth/authenticated-context'
import RawJSON from '../../common/raw-json'
import { decamelise } from '../../common/utils'
import '../../static/css/workflow.css'
import { InteractionMessageNewEnquiryLauncher } from '../../workflow/interaction-message-new-enquiry-launcher'
import { OnChangeContactProps } from '../../workflow/workflow-interaction-message-model'
import { InteractionMessageCard, InteractionMessageCardDisplay } from '../interaction-message-card'
import { useInteractionService } from '../interaction-service'
import { InteractionMessageDetailEnquiryContainer } from './interaction-message-detail-enquiry-container'
import { InteractionMessageDetailSidebar } from './interaction-message-detail-sidebar'
import { InteractionMessageDetailSummary } from './interaction-message-detail-summary'

export interface InteractionMessageDetailViewProps {
  interactionMessage: InteractionMessageFragment
  initialTab?: string
  contactInput?: ContactInput
  onChangeContact: ({ customer, contactId, contactInput }: OnChangeContactProps) => Promise<void>
}

const NEW_ENQUIRY_TAB = 'new'
const SUMMARY_TAB = 'summary'

//hmm, manual IM and order of interaction
export const InteractionMessageDetailView = ({
  interactionMessage,
  initialTab,
  onChangeContact
}: InteractionMessageDetailViewProps) => {
  const userFragment = useAuthenticated().userFragment
  const canViewActiveInteractions = userFragment.callCenter?.participation === UserCallCenterParticipationType.Active

  const [selectedTabId, setSelectedTabId] = useState<string>(initialTab ?? NEW_ENQUIRY_TAB)

  const [initialResults, setInitialResults] = useState<{ enquiryId: string; initialResult: any }[]>([])
  const addInitialResult = ({ enquiryId, initialResult }: { enquiryId: string; initialResult: any }) => {
    setInitialResults([...initialResults, { enquiryId, initialResult }])
  }

  const enquiries = orderBy(interactionMessage.enquiries ?? [], ['created'], ['asc'])

  const tabs: EuiTabbedContentTab[] = [
    {
      id: SUMMARY_TAB,
      name: 'Summary',
      isSelected: selectedTabId === SUMMARY_TAB,
      content: (
        <>
          <EuiSpacer />
          <InteractionMessageDetailSummary interactionMessage={interactionMessage} />
        </>
      )
    },
    ...enquiries.map((enquiry) => {
      const jobNumberString = enquiry.job?.number ? `: ${enquiry.job.number}` : ''
      const baseName =
        enquiry.type !== EnquiryType.Other ? `${decamelise(enquiry.type)}${jobNumberString}` : 'New enquiry'
      const name = enquiry?.aborted?.reason ? `${baseName} (aborted)` : baseName
      return {
        id: enquiry.id,
        name,
        isSelected: selectedTabId === enquiry.id,
        content: (
          <div key={enquiry.id}>
            <EuiSpacer />
            <InteractionMessageDetailEnquiryContainer
              interactionMessage={interactionMessage}
              enquiryId={enquiry.id}
              // contactInput={contactInput}
              result={initialResults.find((r) => r.enquiryId === enquiry.id)?.initialResult}
              onChangeContact={onChangeContact}
            />
          </div>
        )
      }
    }),

    {
      id: NEW_ENQUIRY_TAB,
      name: <EuiIcon type="plusInCircleFilled" />,
      isSelected: selectedTabId === NEW_ENQUIRY_TAB,
      content: (
        <>
          <EuiSpacer />
          <InteractionMessageNewEnquiryLauncher
            interactionMessage={interactionMessage}
            customer={interactionMessage.customer ?? undefined}
            onChangeContact={onChangeContact}
            onEnquiryCreated={(enquiryId, initialResult) => {
              setSelectedTabId(enquiryId)
              addInitialResult({ enquiryId, initialResult })
            }}
          />
        </>
      )
    }
  ]

  const { activeInteractionMessage } = useInteractionService()

  const isCurrentlyActiveInteractionMessageTheSame = activeInteractionMessage
    ? activeInteractionMessage.id === interactionMessage.id
    : true

  const goToAssignedCall = () => {
    if (activeInteractionMessage) {
      const url = new URL(window.location.href)
      const baseUrl = `${url.protocol}//${url.host}`
      window.open(`${baseUrl}/interaction-message/${activeInteractionMessage.id}`, '_blank', 'noreferrer')
    }
  }

  return (
    <>
      {!canViewActiveInteractions ? (
        <div>Beta participants only</div>
      ) : (
        <>
          <EuiFlexGroup direction="column" gutterSize="m">
            <EuiFlexItem grow={true}>
              <InteractionMessageCard
                interactionMessage={interactionMessage}
                display={InteractionMessageCardDisplay.Row}
              />
            </EuiFlexItem>
            {!isCurrentlyActiveInteractionMessageTheSame && activeInteractionMessage && (
              <>
                <EuiFlexItem>
                  <EuiCallOut title="This is not your currently assigned call" color="warning" iconType="help">
                    <EuiFlexItem grow={true}>
                      <EuiText>
                        You are on a previous call page. If this is not intentional please go to your{' '}
                        <EuiLink onClick={goToAssignedCall}>currently assigned call</EuiLink>
                      </EuiText>
                    </EuiFlexItem>
                  </EuiCallOut>
                </EuiFlexItem>
              </>
            )}
          </EuiFlexGroup>

          <EuiSpacer />
          <EuiFlexGroup alignItems="flexStart">
            <EuiFlexItem
              grow={false}
              style={{ minWidth: '350px', width: '350px', paddingTop: '10px' }}
              className="workflow__sidebar"
            >
              <InteractionMessageDetailSidebar
                interactionMessage={interactionMessage}
                onChangeContact={onChangeContact}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={true}>
              {/* Use our own tabs as EuiTabbedContent will destroy each tab when switching but we want to keep the state of partially complete tabs */}
              <EuiTabs size="l">
                {tabs.map((tab) => (
                  <EuiTab key={tab.id} isSelected={tab.isSelected} onClick={() => setSelectedTabId(tab.id)}>
                    {tab.name}
                  </EuiTab>
                ))}
              </EuiTabs>
              <>
                {tabs.map((tab) => (
                  <div key={`div-${tab.id}`} style={{ display: tab.isSelected ? 'block' : 'none' }}>
                    {tab.content}
                  </div>
                ))}
              </>
            </EuiFlexItem>
          </EuiFlexGroup>
          <RawJSON data={interactionMessage} />
        </>
      )}
    </>
  )
}
