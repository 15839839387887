import { useMutation, useQuery } from '@apollo/client'
import { EuiButtonEmpty, EuiFlexGroup, EuiFlexItem, EuiToolTip } from '@elastic/eui'
import { AppliedTagFragment, PropagationType, TagFragment, TagTarget } from '@fallonsolutions/types'
import { useState } from 'react'
import { ApplyTagDocument, RemoveTagDocument, SearchTagsDocument } from '../api/generated-types'
import { useAuthenticated } from '../auth/authenticated-context'
import { TagSize } from './tag'
import { TagModal } from './tag-modal'
import { TagView } from './tag-view'

export interface TagListProps {
  tags: AppliedTagFragment[]
  size?: TagSize
  objectId: string // id of the model to apply tag to
  objectIdentifier: string // job number, customer reference etc
  kind: TagTarget
}

const refetchQueries = ['GetJob', 'GetCustomer']

export const TagList = ({ tags, kind, objectId, objectIdentifier, size = TagSize.Default }: TagListProps) => {
  const tenantId = useAuthenticated().user.tenantId

  const [applyTag, { loading: applyTagLoading }] = useMutation(ApplyTagDocument, {
    refetchQueries,
    onCompleted: () => {
      setApplyTagModalVisible(false)
    }
  })

  const [removeTag, { loading: removeTagLoading }] = useMutation(RemoveTagDocument, {
    refetchQueries,
    onCompleted: () => {
      setRemoveTagModalVisible(undefined)
    }
  })

  const { data, loading: searchTagLoading } = useQuery(SearchTagsDocument, {
    variables: {
      input: {
        filter: {
          targets: [kind]
        }
      }
    }
  })

  const loading = searchTagLoading || applyTagLoading || removeTagLoading

  const tagIds = tags.map((tag) => tag.tag.id)

  const tagsNotApplied = (data?.searchTags?.results ?? []).filter((tag) => !tagIds.includes(tag.id))

  const [applyTagModalVisible, setApplyTagModalVisible] = useState(false)
  const [removeTagModalVisible, setRemoveTagModalVisible] = useState<TagFragment | undefined>(undefined)

  const handleApplyTag = (tagId: string, propagationType: PropagationType) => {
    if (!tenantId) {
      console.log('No tenantId')
      return
    }
    applyTag({
      variables: {
        input: {
          tagId,
          target: {
            id: objectId,
            kind,
            propagation: propagationType
          },
          tenantId
        }
      }
    })
  }

  const handleRemoveTag = (tagId: string, propagationType: PropagationType) => {
    if (!tenantId) {
      console.log('No tenantId')
      return
    }
    removeTag({
      variables: {
        input: {
          tagId,
          target: {
            id: objectId,
            kind,
            propagation: propagationType
          },
          tenantId
        }
      }
    })
  }

  const canApplyTag = tagsNotApplied.length > 0

  const applyTagToolTip = canApplyTag ? `Chose a tag to apply to ${objectIdentifier}` : 'No available tags to apply'

  return (
    <>
      <EuiFlexGroup alignItems="center" justifyContent="center" gutterSize="s">
        <EuiFlexItem grow={false}>
          <EuiFlexGroup direction="row" gutterSize="s">
            {tags.map((tag, index) => (
              <EuiFlexItem grow={false} key={index}>
                <TagView
                  tag={tag.tag}
                  removeTag={() => setRemoveTagModalVisible(tag.tag)}
                  loading={loading}
                  size={size}
                />
              </EuiFlexItem>
            ))}
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiToolTip content={applyTagToolTip}>
            <EuiButtonEmpty
              size="xs"
              iconType={'plus'}
              onClick={() => setApplyTagModalVisible(true)}
              disabled={!canApplyTag}
            >
              Add tag
            </EuiButtonEmpty>
          </EuiToolTip>
        </EuiFlexItem>
      </EuiFlexGroup>
      {applyTagModalVisible && (
        <TagModal
          targetIdentifier={objectIdentifier}
          target={kind}
          tags={tagsNotApplied}
          onClose={() => setApplyTagModalVisible(false)}
          applyTag={handleApplyTag}
          loading={loading}
          kind="Apply"
        />
      )}

      {!!removeTagModalVisible && (
        <TagModal
          targetIdentifier={objectIdentifier}
          target={kind}
          tag={removeTagModalVisible}
          onClose={() => setRemoveTagModalVisible(undefined)}
          removeTag={handleRemoveTag}
          loading={loading}
          kind="Remove"
        />
      )}
    </>
  )
}
