import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import { PaymentTaskLinkFragment } from '../api/generated-types'
import { useAuthenticated } from '../auth/authenticated-context'
import '../static/css/invoice.css'
import { PaymentTaskLink } from './payment-task-link'

interface PaymentTaskRowProps {
  paymentTask: PaymentTaskLinkFragment
}

export const PaymentTaskRow = (props: PaymentTaskRowProps) => {
  const { paymentTask } = props
  const userFragment = useAuthenticated().userFragment
  const canViewPaymentTasks = userFragment.features?.payByLink === true

  return (
    <div className="payment-row">
      {canViewPaymentTasks && paymentTask && (
        <EuiFlexGroup gutterSize="none" justifyContent="spaceAround" direction="column">
          <EuiFlexItem key={paymentTask.id} style={{ padding: '12px' }}>
            <PaymentTaskLink paymentTask={paymentTask} />
          </EuiFlexItem>
        </EuiFlexGroup>
      )}
    </div>
  )
}
