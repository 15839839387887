import {
  EuiButton,
  EuiButtonEmpty,
  EuiButtonGroup,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiRadioGroup,
  EuiRadioGroupOption
} from '@elastic/eui'
import { PropagationType, TagFragment, TagTarget } from '@fallonsolutions/types'
import { useMemo, useState } from 'react'
import { Tag } from './tag'

interface BaseTagModalProps {
  onClose: () => void
  target: TagTarget
  targetIdentifier: string // job number, customer reference etc
  loading: boolean
}

export interface ApplyTagModalProps extends BaseTagModalProps {
  kind: 'Apply'
  applyTag: (tagId: string, propagationType: PropagationType) => void
  tags: TagFragment[]
}

export interface RemoveTagModalProps extends BaseTagModalProps {
  kind: 'Remove'
  tag: TagFragment
  removeTag: (tagId: string, propagationType: PropagationType) => void
}

export type TagModalProps = ApplyTagModalProps | RemoveTagModalProps

const isApplyModal = (props: TagModalProps): props is ApplyTagModalProps => props.kind === 'Apply'

export const TagModal = (props: TagModalProps) => {
  const { target, onClose, targetIdentifier, loading, kind } = props

  const applyMutation = isApplyModal(props)
  const [selectedTagId, setSelectedTagId] = useState<string | undefined>(undefined)

  const tags = applyMutation ? props.tags : []
  const tagToRemove = !applyMutation ? props.tag : undefined

  const [propagationTypeSelected, setPropagationTypeSelected] = useState<PropagationType>(PropagationType.Default)

  const propagationTypeOptions = useMemo(() => {
    const humanFriendlyTypes = {
      [PropagationType.Default]: 'All linked objects',
      [PropagationType.None]: 'Only this object'
    }

    return Object.values(PropagationType).map((propagationType) => ({
      id: propagationType,
      label: humanFriendlyTypes[propagationType],
      value: propagationType
    }))
  }, [])

  const handleOnClick = () => {
    if (!propagationTypeSelected) {
      return
    }
    if (applyMutation) {
      if (!selectedTagId) {
        return
      }
      props.applyTag(selectedTagId, propagationTypeSelected)
    } else {
      if (!tagToRemove) {
        return
      }
      props.removeTag(tagToRemove.id, propagationTypeSelected)
    }
  }

  const canContinue = applyMutation ? !!selectedTagId : !!tagToRemove

  const tagOptions: EuiRadioGroupOption[] = tags.map((tag) => ({
    id: tag.id,
    label: (
      <div style={{ paddingBottom: '9px' }}>
        <Tag tag={tag} />
      </div>
    )
  }))

  return (
    <>
      <EuiOverlayMask>
        <EuiModal onClose={onClose}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              {kind} tag {kind === 'Apply' ? 'to' : 'from'} {target} {targetIdentifier}
            </EuiModalHeaderTitle>
          </EuiModalHeader>
          <EuiModalBody>
            <EuiForm>
              {kind === 'Apply' && (
                <>
                  <EuiFormRow label="Select tag to apply">
                    <EuiRadioGroup
                      options={tagOptions}
                      idSelected={selectedTagId}
                      onChange={(id) => setSelectedTagId(id)}
                      name="Tag"
                    />
                  </EuiFormRow>
                </>
              )}

              <EuiFormRow label="Apply to" fullWidth={true}>
                <EuiButtonGroup
                  legend=""
                  options={propagationTypeOptions}
                  idSelected={propagationTypeSelected}
                  onChange={(option) => setPropagationTypeSelected(option as PropagationType)}
                />
              </EuiFormRow>
            </EuiForm>
          </EuiModalBody>
          <EuiModalFooter>
            <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>

            <EuiButton fill onClick={handleOnClick} disabled={!canContinue} isLoading={loading}>
              {kind} tag
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
    </>
  )
}
